import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Navigation } from "swiper";
import { v4 } from "uuid";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faAward, faShieldAlt } from "@fortawesome/free-solid-svg-icons";

SwiperCore.use([Navigation]);

function Services() {
  const itemList = [
    {
      icon: faAward,
      title: "Screened Profiles",
      description:
        "Every user is verified with multiple layers of verifications.",
    },
    {
      icon: faShieldAlt,
      title: "Secure Payments",
      description:
        "Timely payments and refunds with trusted payment partners",
    },
    {
      icon: faHandshake,
      title: "Playace Support",
      description:
        "Superfluous communication ensuring every query is addressed.",
    },
  ];
  return (
    <section className="container-fluid bg-gray security-section mTop px-3 py-5">
      <div className="security-head text-center">
        <h3 style={{ padding: "0 0 1rem 0" }}>
        Convenient, Safe and Secured!
        </h3>
        {/* <p className="text-muted">
        Convenient, Safe and Secured!
        </p> */}
      </div>

      <Container>
        <Default>
          <Row className="pt-5">
            {itemList.map((item) => {
              return <Item key={v4()} {...item} />;
            })}
          </Row>
        </Default>
        <Mobile>
          <Swiper spaceBetween={50} slidesPerView={1} navigation>
            {itemList.map((item) => {
              return (
                <SwiperSlide key={v4()}>
                  <Item {...item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Mobile>
      </Container>
    </section>
  );
}

function Item(props) {
  return (
    <Col md={4} xs={12} className="text-center">
      <p>
        <FontAwesomeIcon
          icon={props.icon}
          size="5x"
          className="text-primary mb-4"
        ></FontAwesomeIcon>
      </p>
      <h4 className="fw-bold text-primary text-uppercase">{props.title}</h4>
      <div className="w-75 mx-auto">
        <p className="mt-4 text-muted" style={{ fontSize: "13.5px" }}>
          {props.description}
        </p>
      </div>
    </Col>
  );
}

export default Services;