export const AUTHENTICATE_USER_START = 'AUTHENTICATE_USER_START';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';
export const BOOK_CLICKED_TRUE = 'BOOK_CLICKED_TRUE';
export const BOOK_CLICKED_FALSE = 'BOOK_CLICKED_FALSE';

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const UNLOAD_CURRENT_USER = 'UNLOAD_CURRENT_USER';

///v1/event/list/1?page=1&limit=10
export const GET_PARTY_LIST_START = 'GET_PARTY_LIST_START';
export const GET_PARTY_SUCCESS = 'GET_PARTY_SUCCESS';
export const GET_PARTY_FAILURE = 'GET_PARTY_FAILURE';

///v1/property/list
export const GET_PROPERTY_LIST_START = 'GET_PROPERTY_LIST_START';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_FAILURE = 'GET_PROPERTY_FAILURE';


///v1/host-party
export const UPDATE_HOST_PARTY_OBJECT = 'UPDATE_HOST_PARTY_OBJECT';
export const SEND_HOST_PARTY_REQUEST = 'SEND_HOST_PARTY_REQUEST';
export const SEND_HOST_PARTY_REQUEST_SUCCESS = 'SEND_HOST_PARTY_REQUEST_SUCCESS';
export const SEND_HOST_PARTY_REQUEST_FAILURE = 'SEND_HOST_PARTY_REQUEST_FAILURE';

///v1/guest-book
export const UPDATE_GUEST_COUNT = 'UPDATE_GUEST_COUNT';

export const UPDATE_SELECTED_CITY = "UPDATE_SELECTED_CITY";
export const UPDATE_IS_HOST = "UPDATE_IS_HOST";

export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
export const UPDATE_QUOTATION_DATA = "UPDATE_QUOTATION_DATA";
