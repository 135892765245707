import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { axiosInstance } from './api/config';
import Axios from 'axios';

//ADD LOGGER TO SEE GLOBAL STATE CHANGES
const middleware = [logger, thunk];

const setAxiosToken = (state) => {
	let userData = state?.auth?.data;
	if (userData) {
		let object = JSON.parse(userData);
		if (object?.token) {
			axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${object?.token}`;
			Axios.defaults.headers.common['Authorization'] = `Bearer ${object?.token}`;
		}
	} else {
		axiosInstance.defaults.headers.common['Authorization'] = `null`;
		Axios.defaults.headers.common['Authorization'] = `null`;
	}
}
//Save to localstorage
function saveToLocalStorage(state) {
	try {
		setAxiosToken(state);
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (e) {
		console.error(e);
		return undefined;
	}
}

//Load from localstorage
function loadFromLocalStorage() {
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) {
			return undefined;
		}
		let state = JSON.parse(serializedState);
		setAxiosToken(state);
		return JSON.parse(serializedState);
	} catch (e) {
		console.error(e);
		return undefined;
	}
}

//Update the changes in localStorage
const persistedState = loadFromLocalStorage();

const store = createStore(
	rootReducer,
	persistedState,
	composeWithDevTools(
		applyMiddleware(...middleware)
	)
);

//Subscribe to store and check for changes
store.subscribe(() =>
	saveToLocalStorage({
		auth: store.getState().auth,
	})
);

export default store;
