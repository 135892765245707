import React from "react";

export default function SpaceListing() {
  return (
    <div>
      <h4 align="center" className=" maintopTit">Playace.co Services Agreement</h4>
      <p>
        Welcome to Playace.co. We’ve developed an online marketplace to help you
        find great spaces and host exceptional house parties and events. We
        tried to make this Services Agreement easy to read and understand.
        Please take time to read it carefully as it governs your use of our
        platform to book and list spaces and services. Importantly, this
        Agreement contains waivers of class actions and jury trials and an
        agreement to submit all claims and disputes to binding arbitration in
        Section 4 below. If you do not agree to all the terms and conditions of
        this Services Agreement, including those governing disputes in Section
        4, you may not use our platform or services.
      </p>
      <h4>Services Agreement</h4>
      <p>
        This Playace Services Agreement (“Agreement”) is a contract between
        playace.co, which is an entity of Schindae Technologies Pvt. Ltd.
        (“Playace” or “we”) and the person or entity that registered with
        Playace (“user” or “you”). This Agreement describes the terms and
        conditions that apply to your use of the Playace marketplace to book or
        list spaces or other services through our website or apps, and governs
        your hosting spaces for other users; and establishes the obligations
        owed between you and Playace, and between you and other users.
        Throughout this Agreement, the term “Service” means the service allowing
        you to book spaces and services through our website or apps (the
        “Platform”). The term Service does not include any services provided by
        third parties. The terms “Guest” and “Host” refer to the House Party or
        Space Booking or Listing a Space, respectively. A “Space” is the
        location or site that is made available to a Guest to use during
        specific times for a specific purpose (an “Event”), and subject to
        specific Fees and any site-specific terms or limitations. A Host may
        “List” a Space by providing certain details about the Space including
        availability, pricing, and terms or limitations of use; this posting
        about a Space is called a “Listing.” A Guest may “Book” a Space or
        Vendor Services by accepting the times, fees, and any additional terms
        (such as fees for Vendor Services) or limitations of the Listing. A
        “Booking” is (i) only the grant of a limited, temporary, revocable
        license to use a Space, and (ii) if applicable, a contract for Vendor
        Services, in the manner, for the time, and subject to all restrictions
        provided, subject to this Agreement, and as confirmed through the
        Platform. The scope of the use—including times, specific fees, and other
        additional terms or limitations—may also be included in or supplemented
        by a separate “Booking Agreement” that incorporates the terms and
        conditions of this Agreement and referenced policies. A Booking does not
        provide you a lease but only allows you limited access to use the Space
        or other service only as permitted in the Agreement and, as applicable,
        the Booking Agreement.
      </p>
      <p>
        This Agreement is organized into 5 sections:
        <br />
        <span className="section">Section 1: General Terms </span>includes
        general terms describing your Account including creating an account,
        accepting or making payments, and describing how we communicate with
        you.
        <br />
        <span className="section">Section 2: Platform and Service </span>{" "}
        describes how you may and may not use the Platform and Service, and also
        describes how we use any data.
        <br />
        <span className="section">Section 3: Playace Services </span>establishes
        the obligations of Hosts, including how to List a Space and what is
        expected of any Space Listed through the Service; and the obligations of
        Guests, including Booking a Space, expected behavior, responsibility for
        payment of Fees, and Booking other services.
        <br />
        <span className="section">
          Section 4: Termination, Disputes, and Binding Arbitration
        </span>
        describes termination or suspension of this Agreement or your Account,
        and the process of resolving any disputes. This section contains waivers
        of both class actions and jury trials, and an agreement to submit all
        claims and disputes to binding arbitration. Please read this section
        carefully before accepting this Agreement –you may not use the Platform
        or Services if you do not agree to this section.
        <br />
        <span className="section">Section 5: Additional Legal Terms</span>{" "}
        provides additional legal terms including our ability to update this
        Agreement, disclaimers and limitations on our liability. It also
        includes other documents governing your use of the Services including
        our Community Guidelines, Fees Overview, Privacy Policy, and
        Cancellation and Refund Policy. If you have questions about this
        Agreement, please contact us. Your use of the Platform or Services
        constitutes your ongoing acceptance of this Agreement, as amended.
      </p>
      <ol>
        <li>
          <span className="fw-bold">Section 1: General Terms</span>
        </li>
        <ol>
          <li className="fw-bold">Services</li>
          <p>
            Playace provides you access to Services through the Platform. You
            may access the Platform through our website or apps, including those
            for Apple devices. You may use the Service to List Spaces as a Host,
            Book Spaces as a Guest, communicate with other users, and access
            your Account. You may also be permitted to Book services or rentals
            (“Vendor Services”) through third-party service providers (each a
            “Vendor”) or through the Host, which may require you to agree to
            additional terms or conditions. Vendor Services may include
            photography, catering, furniture rentals, or other services that may
            be used as part of your Event. Playace may also provide Services to
            help coordinate Booking Spaces or Vendor Services through the
            Platform or manually (“Concierge Services”). Vendor Services and
            Concierge Services are discussed in more detail in Section 3 below.
            <br />
            We are continuously improving the Platform or Service for all users
            and reserve the right to make changes in our discretion. Changes we
            make to the Platform or Service, including discontinuing certain
            features, affect all users and we try to minimize disruption to
            users. However, these changes may affect users differently. We are
            not responsible if any specific changes we make to the Platform or
            Service adversely affect how you use them.
          </p>
          <li className="fw-bold">Your Account</li>
          <p>
            Before using the Platform or Services, you must create an account
            with Playace (“Account”) and provide us with information about
            yourself or your company. We may ask that you update your Account or
            provide additional or different information at any time. This
            includes requiring additional details about Spaces or payment
            information. Any employees, contractors, or third-parties opening a
            new or using an existing account (collectively, “Agents”) represent
            and warrant that they have authorization to act on behalf of a
            person or entity they claim to represent; and if such authorization
            is not provided by the person or entity, such Agents acknowledge
            personal liability for all obligations and liabilities arising from
            or related to use of the Account by such Agents.
            <br />
            You will be required to provide an email address and password to
            keep your Account secure (“Credentials”), and agree to keep your
            Credentials private and secure. You are responsible for any actions
            taken on your Account using your Credentials whether or not
            authorized or taken by Agents to List or Book a Space or Vendor
            Service. You are solely responsible for the actions or
            communications of your Agents. We are not responsible for and
            disclaim all liability for use of your Account by your Agents.
            <br />
            When creating or using your Account, you may be required to provide
            information about yourself or your Space, or provide optional
            information or comment on other users’ Spaces or Vendor Services
            including text, images, or videos (collectively, “Content”). You
            will only provide Content to us that you own or have authorization
            to provide, and ensure that Content is accurate and complete. You
            must keep any Content current, including your contact or payment
            information. We reserve the right, but shall not be obligated, to
            use public and private data sources to validate the accuracy of any
            Content. This may include validating your identity or business
            information, or verifying information about Spaces. You willprovide
            us any additional information to verify the accuracy or completeness
            of any Content that you provide and we may condition your use of the
            Platform or Service on our ability to verify the accuracy and
            completeness of this Content. If you do not provide required Content
            to us as and when we request it, we may suspend or terminate your
            Account. If you are not at least 18 years old, you may not open an
            Account, access the Platform, or use the Services. Users (including
            Agents) who use the Services or the Platform to List or Book Spaces,
            Vendor Services or Concierge Services on behalf of individuals under
            18 years old, or provide access or use of Spaces by individuals
            under 18 years old, accept personal liability for all acts or
            omissions of such individuals.
            <br />
            You may close your Account at any time by notifying us. You are
            responsible for all activity associated with your Account made
            before it is closed including payment of Fees, Taxes, or Fines;
            providing use of Spaces to Guests as previously Booked; or other
            liabilities caused by or resulting from use of the Platform or
            Service. You understand that we may retain Content and continue to
            display and use any public Content (including comments or reviews of
            Spaces) provided to us prior to closing your Account. At our sole
            option, we may suspend or terminate your Account as provided in
            Section 4(b).
          </p>
          <li className="fw-bold">Compliance with Laws</li>
          <p>
            As used in this Agreement, “Laws” means all applicable federal,
            state, local, and other governmental laws, regulations, ordinances,
            codes, rules, court orders, and all recorded and unrecorded private
            contract, restrictions, covenants and other agreements. You will
            comply with all Laws applicable to your use of the Space, Services,
            Platform, Vendor Services, and Concierge Services, whether as a
            Host, Guest, or Vendor. While we may provide information to help you
            understand certain obligations of using or Hosting Spaces, we are
            not authorized to provide and do not provide any legal advice. You
            are solely responsible for your compliance with Laws and may only
            use the Platform or Service in compliance with applicable Laws. If
            you are unsure how to comply with Laws, you should seek legal advice
            related to Listing or Booking a Space.
          </p>
          <li className="fw-bold">Fees and Taxes</li>
          <p>
            (a) Fees, Overtime, and Deposits. You are responsible for payment of
            fees, expenses, and other amounts related a to Booking (“Fees”).
            Fees include amounts paid to Playace, Hosts, and Vendors and as
            described in more detail on our Fees Overview. Playace receives Fees
            for providing use of the Service and Concierge Services, and Hosts
            and Vendors receive Fees for Bookings. The specific Fees charged are
            presented when you complete, update, or cancel a Booking. Please
            review all Fees before completing a Booking to make sure you
            understand the Fees for the Booking, including our Cancellation and
            Refund Policy. You should contact Hosts or Vendors through the
            Platform before Booking if you don’t understand the Fees listed.
            Please contact us if you have any questions about Fees or our
            Cancellation and Refund Policy.
            <br />A Booking provides a limited use of a Space or Vendor Service
            as described in the Listing and Booking Agreement, if applicable. If
            you use a Space or Vendor Service beyond the Booking you will be
            responsible for payment of additional Fees (“Additional Fees”) as
            described on the Fees Overview. We may calculate the Additional Fees
            based on your exceeding the time (for example, a calculated hourly
            rate billed in 30-minute increments) or other costs associated with
            your exceeding the permitted usage of the Booking (for example,
            exceeding the number of Event attendees). You are solely responsible
            for any damage done to the Space or the Amenities by anyone
            attending or working at your Event. You are expected to pay for the
            damages if any to the Host at the Venue itself at the time of the
            incident. Hosts must notify us within 3 days of a Guest exceeding
            the permitted use of the Space and specifically request payment of
            Additional Fees. Any dispute regarding Additional Fees will be
            resolved as described in Section 4.3.Certain Bookings may require
            you to provide a portion of prepaid Fees or a refundable amount
            (“Deposit”) in advance. All Fees and Deposits, as applicable, will
            be identified prior to completing your Booking. If held by Playace,
            at our option, Deposits may be credited to your Booking or returned
            when the Space is returned in acceptable condition and free of any
            material damage.
            <br />
            While we may facilitate the collection of Fees and Deposits for
            Hosts, once remitted to Hosts, we are not responsible for the return
            of Fees or Deposits to Guests, where applicable.
            <br />
            (b) Taxes and Fines. You are solely responsible for payment of all
            taxes, levies, penalties, and other costs imposed by any taxing
            authority or government agency related to Listing or Booking Spaces
            or Vendor Services including any sales or occupancy tax, indirect
            taxes such as valued added tax (VAT) or goods and services tax
            (GST), usage or permitting fees, duties, and other taxes imposed by
            municipalities, states, or governments through regulation,
            ordinance, law, or judicial or regulatory interpretation
            (collectively “Taxes”). Except as required by Law, Playace will not
            calculate, track or pay Taxes or submit Tax reporting on your
            behalf. You are responsible for all Taxes owed for Booking or
            Listing a Space, or providing or using Vendor Services including,
            without limitation, accurate calculation of Taxes due, timely
            remittance of Taxes to the appropriate taxing authority and
            maintenance of any required records and accounts. If any taxing
            authority demands that we pay such Taxes on your behalf, you are
            immediately liable to us for such Taxes and will reimburse or pay
            Playace for such Taxes upon demand. You are also responsible for any
            penalties arising from your failing to comply with this Agreement
            including those issued by regulatory or taxing authorities, law
            enforcement, fire code or safety agencies, or other third parties;
            or that may be issued by us for losses we or users incur that are
            based on your failing to comply with this Agreement or misuse of the
            Platform, Services, Space, or Vendor Services (collectively,
            “Fines”). You understand and agree that Playace does not provide you
            with any advice or guidance of any kind or nature regarding Taxes
            and that you have been advices to consult with your tax advisor for
            any required advice or guidance regarding Taxes.
            <br />
            (c) Payment. You will timely and fully pay any Fees, Deposits,
            Taxes, Fines, or other amounts you owe under this Agreement. If you
            owe amounts and we are unable to receive payment through the
            Platform for any reason, then we may require that you pay through
            other means (such as direct debit, wire transfer, or cashiers’
            check). We may set-off any amounts owed to us through collection of
            funds that would otherwise be payable to you through the Platform.
            You are responsible for any costs or expenses associated with our
            recovering Fees, Taxes, or Fines owed, including our attorneys’ fees
            or expenses. In our discretion, any late payments of more than 7
            days may incur a late charge of up to ten percent (10%) and
            accumulate interest of ten percent (10%) per month until delinquency
            is resolved.
          </p>
          <li className="fw-bold">Receiving Payment</li>
          <p>
            Acceptance and payment of funds between users or Playace on the
            Platform (“Payment Processing”) is provided by Razorpay. Your use of
            Payment Processing is subject to the Razorpay Account Agreement that
            includes the Razorpay Services Agreement as may be modified by
            Razorpay from time to time (collectively, the “Razorpay Agreement”).
            As a condition using Payment Processing, you must provide accurate
            and complete information about you and your business and you
            authorize us to share this information to Razorpay. All bank and
            credit card information is sent directly to and stored with Razorpay
            using their security protocols. Playace does not store your payment
            information on its systems and shall not have any responsibility for
            the safety or security of that information. Your use of Payment
            Processing is conditioned upon your compliance with the Razorpay
            Agreement, and if the Razorpay Agreement is terminated by Razorpay
            you may not be able to use the Platform, or have your Account
            suspended or terminated.
            <br />
            We may change or add other payment processing services at any time
            upon notice to you, which may be subject to additional terms or
            conditions.
          </p>
          <li className="fw-bold">Communication and Notices</li>
          <p>
            We may communicate with you and provide you information or notices
            regarding your Account or transactions through email, telephone,
            text message or SMS, or through messaging on the Platform. You will
            promptly respond to any communications you receive and understand
            that failure to do so may impact your ability to Book or List
            Spaces, or use the Platform or Services.
            <br />
            We may send you notices to the email address or physical address
            included in your Account, through messaging on the Platform, or (for
            businesses) to the address of your headquarters or registered
            business, or (for individuals) to your residence. You may send any
            legal notices to us at crew@playace.co. You agree that any email
            notice from us is considered accepted by you one day after such
            notice was sent and will have the same legal effect as if it were
            physically delivered to you.
            <br />
            You agree to receive any communications from us and transact with us
            electronically. This acceptance of emails or other electronic
            messages constitutes your consent and your electronic signature has
            the same legal effect as physically signing a document. You may
            withdraw this consent to transact electronically at any point by
            providing notice to us. However, given that electronic communication
            is integral to the Platform and the Services, following any such
            notice we may elect to close your Account.You specifically consent
            to receive communications related to your Account through text
            messages, SMS, or other communications sent to your mobile device
            (“Mobile Notifications”). Standard mobile rates will apply for any
            Mobile Notifications you receive.
            <br />
            To receive electronic communications you must keep your hardware and
            software up to date. If you are having problems receiving
            communications, please first verify that your hardware and software
            have all current updates installed. If you still have problems
            communicating receiving messages,please first check our support
            pages and, if you cannot resolve the issue yourself, please contact
            us.
          </p>
          <li className="fw-bold">Communication with Other Users</li>
          <p>
            The Platform allows you to communicate with other users without
            disclosing sensitive personal contact information. It is your
            responsibility to use good judgment in the information you provide
            to other users. You may use the Platform only to List or Book Spaces
            or Vendor Services, communicate with us or other users, resolve
            disputes, or use other functionality we provide to you through the
            Platform. You may not use the Platform to send messages that are
            unwanted or unrelated to a Listing or Booking through the Platform,
            use the Platform to harass or attempt to market other services to
            users, or send spam.
            <br />
            We strongly recommend that you should use the Platform to
            communicate with other users. If you use other means of
            communication you understand that you may be putting your personal
            contact information at risk of misuse. You also understand that any
            communications made outside of the Platform may impair your ability
            to recover all or some amounts owed to you in the event of a dispute
            between you and another user. This is explained further in Section
            4.3.
            <br />
            We are not responsible for, and disclaim all liability resulting
            from, any losses or harm to you resulting from sharing personal or
            sensitive information with other users, or communicating or engaging
            with users outside of the Platform. More information regarding
            appropriate communications conduct when using Playace is included in
            our Community Guidelines.
            <br />
            Playace, at its sole option and without notice or any obligation to
            do so, may from time to time (i) remove communications among users
            which contain or share personal contact information, or (ii) suspend
            or terminate the accounts of users that share personal contact
            information.
          </p>
          <li className="fw-bold">No Endorsement</li>
          <p>
            We provide a Platform to help users connect with each other on the
            Platform but do not endorse any specific Guests, Hosts, Spaces, or
            Vendors or Vendor Services registered with or offered through the
            Platform. Any designations or badges provided through the Platform
            are based on the information we have available to us which may be
            provided by other users or third parties and which may be inaccurate
            or out of date and do not constitute endorsement. Each user is
            independent, and Hosts and Vendors are independent contractors,
            unaffiliated with Playace, that are solely responsible for the
            character, quality, utility and provision of any Space or Vendor
            Service they provide or do not provide to you.
          </p>
        </ol>
        <li>
          <span className="fw-bold">Section 2: Platform and Service</span>
        </li>
        <ol>
          <li className="fw-bold">Ownership, License, Restrictions</li>
          <p>
            Playace owns all right, title, and interest in the Platform,
            Services, and all intellectual property embodied or contained in
            them (individually and collectively, “IP”). IP includes all
            registered or potential patents, copyrights, trademarks, trade
            secrets, and other proprietary rights. Through your Account, we
            grant you a limited, temporary, revocable, nontransferable,
            non-exclusive license to use the Platform and Service for the
            purposes described in this Agreement and only as provided to you
            through the Platform. This license does not constitute a transfer of
            ownership or grant you any additional rights to use the IP. You may
            not register, claim ownership in, or sublicense the Platform,
            Services, or IP; use the Platform, Service, or IP in violation of
            this Agreement or Laws; or reverse engineer or copy all or any
            portion of the Platform, Service, or IP (except as expressly
            permitted). We may suspend or close your Account and pursue legal
            action against you if we believe or determine that your use of the
            Platform, Service, or IP exceeds the scope of this grant; or that
            you are attempting to hack or disrupt the use of the Platform,
            Service, or IP by others; or that you are otherwise interfering with
            the normal operation of the Platform or Service.
            <br />
            We may provide you logos or other digital media (“Logos”) to help
            you advertise your Space to potential Guests. Where provided, you
            agree to use Logos only to advertise your Space. You may not use any
            Logos, the Platform, or the Service for any unlawful competitive use
            or in a manner that harms or tarnishes the Playace brand or
            reputation; and you are prohibited from modifying or using Logos in
            any manner that is inconsistent with our authorization or
            guidelines. We may terminate your right to use any Logos in our
            discretion upon notice to you in which event you shall cease using
            any such Logos.
          </p>
          <li className="fw-bold">Community Guidelines</li>
          <p>
            We have established Community Guidelines that set our expectations
            for all users on the Platform. You will review and abide by the
            Community Guidelines whenever using the Platform or Services,
            communicating with other users, or using or providing use of Spaces.
            If you believe that another user is violating the Community
            Guidelines, please contact us. Playace shall have no duty to monitor
            users’ compliance with or to enforce the Community Guidelines and
            shall have no liability for any user’s violation of the Community
            Guidelines.
          </p>
          <li className="fw-bold">Content</li>
          <p>
            (a) Posting Content. You represent and warrant that you are
            authorized to provide Content to the Platform and that any Content
            you provide does not violate the proprietary or privacy rights of a
            third party. You may not provide any Content that is copyrighted by
            third parties without their express permission. You grant Playace a
            fully-paid, worldwide, nonexclusive, perpetual license to use, copy,
            transmit, distribute, modify, publicly display, and sublicense any
            Content you provide to us. This grant includes our ability to use
            any Content for both internal use (such as analysis to improve the
            Platform or Services) or external use (such as in marketing or
            online advertising). If you cannot provide us the above grant then
            you may not provide Content to us. You agree to indemnify, defend
            and hold us harmless for any damages or losses based on third-party
            claims that Content violates proprietary or privacy rights.
            <br />
            (b) Restrictions on Certain Content. You may never post any Content
            that (i) is defamatory, obscene, profane, or pornographic; (ii) is
            abusive, harassing, or disrespectful of other users; (iii) violates
            applicable Laws, including those prohibiting discrimination, false
            advertising, privacy, or unlawful marketing; (iv) is intended to
            deceive or mislead, is false or inaccurate, or misrepresents the
            nature or condition of a Space; (v) contains marketing or
            promotional content unrelated to the details of a Space; or (vi)
            includes sensitive personal information, including payment, contact
            information, or personal account details. You will not knowingly or
            negligently provide any Content that contain viruses, Trojan horses,
            and other harmful content (collectively, “Viruses”). While we
            attempt to identify any Viruses, we are not liable for and disclaim
            all responsibility for ensuring that Content is free from Viruses.
            <br />
            (c) DMCA Notices. If you believe that any Content posted violates
            your copyright, please notify us at crew@playace.co This notice
            should identify the specific Content and provide us with evidence of
            your ownership of the copyright or authorization to enforce the
            rights of the copyright owner. We will internally review alleged
            violations but may not send you a response each time notifying you
            of the results of our review. Any information you provide to us may
            be shared with other users, third parties, or law enforcement to
            help assess the claim of infringement or remove infringing content.
            We will remove any content we determine to be infringing consistent
            with our obligations under the Digital Millennium Copyright Act
            (DMCA).
          </p>
          <li className="fw-bold">Privacy and Data Usage</li>
          <p>
            Our Privacy Policy describes our collection, use, storage, and
            sharing personal information. This includes personal information
            included in Content and information collected through use of the
            Platform. We may anonymize, pseudonymize, or aggregate any
            information, including personal information or Content, and use this
            information for any purpose including improvement of the Platform or
            Services, or creating or distributing public marketing collateral.
          </p>
          <li className="fw-bold">Support, Feedback</li>
          <p>
            We provide you basic information to help you use the Platform and
            Services at our Support Site. While we try to maintain accurate
            support information we are continuously improving the Platform and
            Services and cannot guarantee that the information provided through
            our Support Site is always accurate or current. While we may also
            provide email or phone support from time to time, we cannot
            guarantee that you will, where provided, receive a response within
            any specific timeframe.
            <br />
            We appreciate any ideas, suggestions, or feedback you voluntarily
            provide to help us improve the Platform or Services (“Feedback”).
            Any Feedback you provide us is ours to use, register, modify,
            monetize, and otherwise use. You grant us all title and interest in
            any Feedback you provide to us and, if necessary, agree to assist us
            in establishing our ownership. You acknowledge that you will not
            receive any compensation for providing us Feedback.
          </p>
        </ol>
        <li>
          {" "}
          <span className="fw-bold">Section 3: Playace Services</span>
        </li>
        <ol>
          <li className="fw-bold">Listing Spaces </li>
          <p>
            {" "}
            This section applies to Hosts (a) Listings. When you List a Space as
            a Host, you must provide details about the Space including a
            description, how it may be used, the cost of a Booking, a list of
            any facilities, current pictures, and other details about its
            potential uses and condition (collectively, “Description”). This
            includes details regarding use of any amenities, furniture,
            equipment, Host provided Vendor Services available at an additional
            charge, or fixtures that are part of or in the Space (collectively,
            “Amenities”). While it is important to communicate excitement and
            the benefits of your Space, the Description must be accurate and
            give potential Guests a reasonably good understanding of how they
            may use the Space for their Event. Descriptions may not include any
            contractual obligations or alter Guests’ legal liabilities from
            those described in this Agreement including (i) modifying or
            expanding Guests’ financial responsibilities or limit Guests’
            ability to seek recourse or restitution under this Agreement (unless
            expressly permitted by Playace when applicable in a Booking
            Agreement), (ii) violate the Community Guidelines or Laws, or (iii)
            require Guests to pay you directly outside the Platform.
            <br />
            (b) Transacting with Guests. All Hosts must comply with our
            Community Guidelines when Listing a Space, providing a Description,
            and in transacting with Guests. You are responsible for maintaining
            the Space and Amenities so that Guests may reasonably use them as
            provided in the Description and Booking. Spaces and Amenities must
            be in good working order and provided to Guests in a safe, clean and
            usable condition. Any plumbing, electrical, structural or other
            physical deficiencies or defects must be corrected before you List a
            Space. Except as specified in a Booking Agreement, all water,
            electricity, HVAC and other utility services (exclusive of telephone
            and data services) are provided with the Space at no additional cost
            <br />
            (c) Booking Agreements. We may elect to require a Booking Agreement
            with your Listing that includes all or part of the Description
            including a listing of any Amenities and any additional terms and
            conditions. Where required, the Booking Agreement will incorporate
            this Agreement and supplement and be a part of the binding agreement
            between you and a Guest. Except where approved by Playace, Booking
            Agreements may not impose rules or limitations on use that are
            materially different than those listed in the Description, include
            any additional contractual obligations, or alter Guests’ or Hosts’
            liabilities.
            <br />
            (d) Recordings. During their Event, Guests may photograph, film, or
            otherwise record events when using Spaces and Amenities (“Event
            Recordings”). Except as expressly prohibited by federal, state,
            local or other governmental Laws or as otherwise set forth in the
            Description or any Booking Agreement, you grant to any Guest that
            Books a Space a fully-paid, worldwide, non-exclusive, perpetual
            license to use, copy, transmit, distribute, modify, publicly
            display, and sublicense Event Recordings that may include or
            identify the Space or Amenities.
            <br />
            (e) Conduct and Fees. As a Host, you are solely responsible for
            ensuring that Spaces and Amenities comply with all applicable Laws
            including any local ordinances related to the condition, licensure,
            or registration Spaces for use by Guests, and payment of Taxes. We
            may condition your continued use of the Platform and Services on
            your providing proof, to our reasonable satisfaction, of your
            compliance with Laws at any time. All Fees owed by Hosts are subject
            to the Fees Overview and Cancellation and Refund Policy.
            <br />
            (f) Preparation for and Supervision of Event. As a Host, you are
            solely responsible for (i) preserving and protecting your Space and
            Amenities by removing and/or securing valuable, vulnerable or
            sensitive items, (ii) determining the appropriate types and
            intensities of permitted uses in your Space, (iii) evaluating the
            appropriateness of potential Guests, and (iv) supervising and
            monitoring the Space and Amenities and your Guest’s use of the Space
            and Amenities for the Event, to the extent (if any) you as Host
            determine in your sole judgment.
            <br />
            (g) Understanding risks: By listing your Space with us, you
            understand that there maybe certain risks involved in terms of
            damages to the property by the guests and their conducts. You agree
            that you will not hold playace.co responsible for any such damages
            and conduct of the guests. You understand that playace.co is just an
            aggregator, which provides you and additional source of income by
            connecting your space with other users who may want to book it.
          </p>
          <li className="fw-bold">Booking Spaces and Services </li>
          <p>
            {" "}
            This section applies to Guests (a) Bookings. As a Guest, you should
            review the Description and availability to confirm they are
            appropriate for your Event before Booking a Space or Vendor Service.
            The Platform allows you to confirm any details or ask the Host any
            specific questions about the Space or Vendor Service, or confirm
            details of a Booking, without sharing your personal contact
            information. Prior to Booking, you will need to provide payment
            information through the Platform. Booking Fees and any Deposit, if
            applicable, will be shown before you complete your Booking. You are
            responsible for all Fees and Taxes associated with the Booking. All
            Bookings are subject to our Cancellation and Refund Policy. When you
            Book a Space, you are only provided a license to use the Space as
            described in the Booking and confirmed by the Host, subject to this
            Agreement and any Booking Agreement. A Booking does not provide you
            a lease or access or use of the Space beyond the specified time and
            Description.
            <br />
            (b) Conduct and Fees. You will comply with the Community Guidelines
            and any Booking Agreements throughout the Booking and use the Space
            or Vendor Service only as permitted or agreed upon and consistent
            with the Description, and assure that any attendees do the same.
            During your Booking, you are responsible for (i) the behavior and
            acts of any attendees, service providers, or others that access the
            Space, (ii) ensuring that the use does not exceed any limitations
            identified in the Booking, (iii) coordinating the timely setup or
            breakdown of your Event, or (iv) complying with applicable Laws
            including acquiring any required licenses or permits for your Event,
            hiring security personnel for larger Events, or limiting noise to
            certain times of the day. You are responsible for and accept all
            liability for any damage done to the Space or Amenities during your
            Event by attendees or service providers whether intentional or not,
            for your failure to comply with applicable Laws, and for any Fines
            you incur. You agree to return the Space to the Host in
            substantially the condition as provided to your or as otherwise
            agreed in any Booking Agreement and consistent with the Community
            Guidelines, and to promptly notify Hosts of any damage done to the
            Space or Amenities. All Fees owed by Guests are subject to the Fees
            Overview and Cancellation and Refund Policy.
          </p>
          <li className="fw-bold">Required and Supplemental Insurance</li>
          <p>
            (a) Required Insurance. Every user will acquire and maintain all
            insurance as required by Law and suitable for you or your business.
            You are solely responsible for understanding and evaluating what
            insurance is appropriate to cover damage, loss, injury, legal
            liability, and other harm specific to you, your business, those
            attending the Event, third parties, the Space or Amenities, and
            deciding what coverage, limits and providers are appropriate for
            you.
            <br />
            We may, but are not required to, offer you the option to buy certain
            insurance provided through third parties for your Event through the
            Platform or Concierge Services. We cannot assure and expressly
            disclaim (i) any liability to you or third parties that any
            insurance offered or acquired to you through the Platform will
            appropriately cover or mitigate risks to you, your business, those
            attending the Event, third parties, the Space or Amenities, or (ii)
            endorsement of the quality, character or financial capacity of any
            insurance company offering insurance products through the Platform
            or Concierge Services.
            <br />
            (b) Supplemental Insurance. Playace may, in its sole discretion,
            obtain and maintain supplemental insurance policies providing
            limited supplemental protection of Hosts against liability to
            Guests, Event attendees or third parties for personal injury or
            property damage sustained by Guests, Event attendees or third
            parties (a “Host Policy”). Where we elect to provide a Host Policy,
            this does not cover damage to the Space or Amenities and does not
            eliminate or reduce your obligation to maintain insurance as
            provided in Section 3.3(a). Further, where provided, recovery under
            a Host Policy will be (i) available only where a Host fully complies
            with this Agreement, (ii) supplemental to any insurance maintained
            by Hosts, (iii) limited to the terms, conditions, and exclusions of
            the Host Policy, (iv) subject to the then applicable claims
            procedure established by Playace and the issuer of the Host Policy,
            and (v) subject to a Host promptly notifying the Playace support
            team of any potential claims. Further, Playace or its insurers may
            amend, modify, or terminate any Host Policy at any time, with or
            without notice to you.
          </p>
          <li className="fw-bold"> Listing or Booking Agents </li>
          <p>
            {" "}
            This section applies to Agents that List or Book Spaces on behalf of
            users Except where agreed upon in writing by Playace, if you List or
            Book as an Agent, you are directly responsible for complying with,
            and causing the user to comply with, the terms of this Agreement and
            any Booking Agreement. This includes the obligations stated in
            Sections 3.1 (for Hosts) and 3.2 (for Guests). In addition, upon our
            request, you will provide authorization from the party that you
            represent as Agent.
          </p>
          <li className="fw-bold"> Vendor Services </li>
          <p>
            You may have the ability to Book Vendor Services as part of your
            Booking or Event. Vendors are independent third-party contractors
            and are not affiliated with or under the control of Playace. Hosts
            may also provide Vendor Services that can be Booked in addition to
            their Space. Vendor Services may be available to Book through the
            Platform or we may help you do so through Concierge Services. You
            understand that Vendor Services are provided by Vendors and not
            Playace. We are not responsible for and disclaim all responsibility
            for and liability resulting from Vendors’ actions or failure to act,
            for the nature or quality of any Vendor Services, and for any loss
            or injury you or others sustain arising out of or related to Vendor
            Services. You acknowledge that Playace may be paid compensation by
            one or more Vendors in connection with a Booking of Vendor Services.
          </p>
          <li className="fw-bold">Concierge Services</li>
          <p>
            We provide Concierge Services to help you coordinate Bookings of
            non- Host provided Vendor Services for your Booking or Event.
            Concierge Services may not be available in every city or region and
            we cannot guarantee that there will be Concierge Services available
            for specific Spaces or Concierge Services to fit your specific
            needs. Where provided, we will work with you to establish a budget
            and other requirements for your Booking that includes Concierge
            Services. We will try to identify Spaces or Concierge Services that
            meet those requirements and will submit any proposals to you for
            review and acceptance. If accepted, we will coordinate any
            communication and payments between you and the Host or Vendors
            resulting from the Concierge Services. When Concierge Services are
            added to your Booking, we will charge additional Fees for use of
            Concierge Services. You remain obligated to pay us any Fees for
            Concierge Services even if Vendor Services (other than Host provided
            Vendor Services) are confirmed or paid for outside the Platform. As
            with all Bookings, Playace is not responsible for and expressly
            disclaims all liability for Hosts’ or Vendors’ actions or failure to
            act, and for any loss or injury you or others sustain arising out of
            or related to Vendor Services.
          </p>
          <li className="fw-bold"> Cancellations and Refunds</li>
          <p>
            All cancellations and any refunds that may be available to you are
            subject to our Fees Overview and Cancellation and Refund Policy.
          </p>
        </ol>
        <li>
          <span className="fw-bold">
            Termination, Disputes, and Binding Arbitration
          </span>
        </li>
        <ol>
          <li className="fw-bold"> Term, Termination, and Survival</li>
          <p>
            (a) Term and Termination By User. You consent to this Agreement when
            you first access or use the Platform or Services, and your continued
            use of the Platform and Services constitutes your ongoing consent to
            this Agreement as amended from time to time. You may terminate this
            Agreement by closing your Account with us, however this will not
            immediately terminate any ongoing rights or obligations you or we
            may have. This includes any obligations to pay for Bookings, to
            honor any Bookings made before termination, or to pay Fees, Taxes or
            Fines due; or any liabilities that you incurred prior to
            termination.
            <br />
            (b) Suspension or Termination by Playace. We may suspend your
            Account--including your ability to communicate with other users or
            receive payments, or complete a Booking--or terminate this Agreement
            and your Account at any time including, without limitation, (i) if
            we believe that use of your Account poses a risk to Playace, you,
            other users, or third parties, (ii) actual or potential fraud by you
            or on your behalf, (iii) your failure to respond to communications
            from us or other users, or (iv) your failure to comply with this
            Agreement or applicable Law.
            <br />
            (c) Survival. The following provisions will also survive termination
            of this Agreement: Sections 1.3 (Compliance with Laws), 1.4 (Fees
            and Taxes), 1.6 (Communication and Notices), 1.7 (Communication with
            Other Users), 1.8 (No Endorsement), 2.1 (Ownership, License,
            Restrictions), 2.2 (Content), 2.4 (Privacy and Data Usage), 2.5
            (Support, Feedback), Section 3.1(d)(Recordings) and (e)(Conduct and
            Fees) for Hosts, Section 3.2(b) (Conduct and Fees) for Guests, 3.3
            (Required and Supplemental Insurance), 3.4 (Listing or Booking
            Agents), 3.5 (Vendor Services), 3.6 (Concierge Services), 3.7
            (Cancellations and Refunds), 4 (Termination, Disputes, and Binding
            Arbitration), and Section 5 (Additional Legal Terms).
          </p>
          <li className="fw-bold"> Binding Arbitration</li>
          <p>
            This section describes how disputes or claims arising under this
            Agreement between you and Playace or between you and another user
            (not resolved through the process set forth in Section 4.3) will be
            resolved. It includes waivers to both a jury trial and your ability
            to join other plaintiffs as part of a class action. Please read this
            section carefully before accepting this Agreement—you may not use
            the Platform or Services if you do not agree to this section.
            <br />
            (a) Process for Arbitration. Subject to the exclusions provided in
            Section 4.2(b) and the process provided in Section 4.3, all
            disputes, claims, and controversies arising under or related to this
            Agreement between you and Playace or between you and another user
            (if not resolved pursuant to Section 4.3 below) will be resolved
            through binding arbitration as follows:
            <br />
            • (i) if the amount of the dispute, claim, or controversy is
            reasonably less than Rs. 20,00,000 (INR), resolution shall be
            administered online or offline by an arbitration provider of our
            choosing in accordance with their applicable arbitration rules and
            procedures effective at the time a claim is made. You consent to
            receive electronic service of process at the email associated with
            your Account. Where you are delinquent in responding to such
            process, you will be responsible for any attorney, court, or other
            fees associated with the delinquency. The party filing the User
            Dispute will be responsible for payment of any costs associated with
            that filing, including costs borne by Playace. As a part of the User
            Dispute, you may also seek to recover these costs if you prevail.
            <br />
            • (ii) if the amount of the dispute, claim or controversy is
            reasonably Rs. 20,00,000 (INR) or more, resolution shall be before a
            single arbitrator and administered by senior government officials in
            Mumbai, Maharashtra, of our choosing. This includes but is not
            limited to any statutory or common law claims relating to breach,
            enforcement, or interpretation of this Agreement and any Booking
            Agreement. Any such arbitration will take place in the county where
            the Booked or Listed Space is located, unless you and Playace
            mutually agree otherwise. The arbitrator will apply the substantive
            Laws of Maharashtra State. All claims from Rs. 20,00,000 (INR) to
            Rs. 2,00,00,000 (INR) shall be subject to the laws of Maharashtra
            State Streamlined Arbitration Rules. To initiate such an
            arbitration, a party will provide a written demand that states both
            the basis of the claim and the desired relief. Each party
            irrevocably and unconditionally consents to service of process
            through personal service at their corporate headquarters, registered
            address, or primary address (for individuals or sole proprietors).
            Nothing in this Agreement affects the right of any party to serve
            process in any other manner permitted by Law.
            <br />
            Once arbitration is initiated as provided in Subsections (i) or (ii)
            above, the parties will share the costs of the arbitration,
            facilities, and arbitration reporters (as necessary) equally except
            as otherwise determined by the arbitrator. Each party will be
            responsible for its own attorneys’ fees and legal costs. The
            arbitrator may award the prevailing party recovery of any of the
            costs of arbitration or legal fees as they see appropriate. The
            arbitrator may provide for any monetary or other remedies that are
            available under applicable Law but may not modify the terms of this
            Agreement or any Booking Agreement. The arbitrator will provide a
            reasoned decision addressing the specifics of the dispute. The
            decision is binding and not subject to appeal. The parties will act
            promptly to respect the decision of the arbitrator, including
            payment of any amounts owed or taking of any action required.
            <br />
            Any judgment on the award rendered by the arbitrator may be entered
            in any court having jurisdiction.
            <br />
            (b) Certain Claims Excluded. Notwithstanding Section 4.2(a), the
            parties agree that any claims based on ownership or misuse of the
            other party’s intellectual property—including patents, copyrights,
            or trademarks—may be brought before the state or Federal courts
            located in Mumbai, Maharashtra, India. Either party may also seek
            provisional remedies for injunctive relief under such claims from a
            court of competent jurisdiction.
            <br />
            (c) Class Action and Jury Waiver. Each party agrees that any action
            or claim arising from or related to this Agreement or any Booking
            Agreement may only be brought on an individual basis and not part of
            a class action or consolidated arbitration, or join claims with
            other users or third parties. Further, each party expressly waives
            its right to a jury in arbitration and court, where permitted. You
            may opt-out of this class action and jury waiver described in
            Section 4.2(c) by emailing us at crew@playace.co within 30 days of
            your first use of the Platform or Services. You must include your
            name, phone number, physical address, and email address in your
            opt-out notice. This is your only mechanism for opting out of this
            Section 4.2(c) and failure to do so as described constitutes your
            consent to this waiver. If you choose to opt out of this Section
            4.2(c), please note that all other provisions in this Agreement will
            remain intact and in full force and effect.
            <br />
            (d) Conflict of Rules. If any provision of this Section 4.2 is found
            to be invalid or unenforceable, the reviewing court or arbitrator,
            as applicable, will interpret or revise the provisions only as
            minimally necessary to comply with Law. All the other provisions
            will remain enforceable and intact as written.
          </p>
          <li className="fw-bold">Disputes Between Users</li>
          <p>
            This section describes how disputes or claims arising under this
            Agreement between you and another user will be resolved. Please read
            this section carefully before accepting this Agreement—you may not
            use the Platform or Services if you do not agree to this section.
            <br />
            (a) Initial User Dispute Resolution. You agree to first attempt to
            resolve any disputes, disagreements, or claims that you have with
            other users (“User Dispute”) in good faith through the Platform. If
            you are unable to resolve the User Dispute, you then will submit the
            User Dispute to us for review by submitting a written summary of the
            User Dispute, including any documentation, to us at crew@playace.co
            In our sole discretion, we may (i) require that you submit
            additional details regarding any User Dispute, and/or (ii) hold any
            pending payments or reverse any payments already made to you and
            hold such payments pending final resolution of the User Dispute. We
            will review the summary and communications made on the Platform. We
            may, but are not required to, also review communications made
            outside of the Platform. Upon review and investigation, we will
            either (a) provide you and the other user our conclusion based on
            the summary provided, which you agree to accept as final and binding
            determination with the same force and effect as if determined
            through arbitration as provided in Section 4.2; or (b) require that
            the dispute is resolved through binding arbitration adjudicated by a
            third party as provided in Section 4.2(a). The party filing the User
            Dispute will be responsible for payment of any costs associated with
            that filing, including costs borne by Playace. As a part of the User
            Dispute, you may also seek to recover these costs if you prevail.
            Any payments held by Playace pending final resolution of a User
            Dispute shall be disbursed by Playace as determined by Playace, the
            arbitrator, or a court of competent jurisdiction.
            <br />
            (b) User Disputes Under Rs. 20,00,000 (INR). After following the
            process outlined above, if the User Dispute relates to an amount
            reasonably less than Rs. 20,00,000(INR) you agree to submit the User
            Dispute to binding arbitration as provided in Section 4.2(a)(i)
            above.
            <br />
            (c) User Disputes of Rs. 20,00,000 (INR) or higher. After following
            the process outlined above, if the User Dispute relates to an amount
            reasonably Rs. 20,00,000 (INR) or more, you agree to submit the User
            Dispute to binding arbitration as provided in Section 4.2(a)(ii)
            above.
          </p>
          <li className="fw-bold">Confidentiality of Proceedings</li>
          <p>
            Any proceedings pursuant to this Section 4 and their results will be
            maintained as confidential by all parties. Except as may be required
            by Law, the parties and those persons participating in the
            proceedings on their behalf will not disclose and will maintain the
            confidentiality of all materials, testimony, and evidence provided
            during the proceeding as well as the results of such proceeding. The
            parties agree to enter into a separate confidentiality agreement or
            order, as appropriate, to maintain the confidentiality of the
            proceedings.
          </p>
        </ol>
        <li>
          <span className="fw-bold">Additional Legal Terms</span>
        </li>
        <ol>
          <li className="fw-bold">Right to Amend</li>
          <p>
            We may amend or modify this Agreement at any time by posting the
            modified Agreement on our website, sending you a copy via email, or
            otherwise communicating the amendment to you through the Platform.
            Your continued use of the Platform or Services after we amend or
            modify this Agreement constitutes your consent to the revised
            Agreement. If you do not agree to the revised Agreement, you must
            close your Account by contacting us.
          </p>
          <li className="fw-bold"> Force Majeure; Cancelations</li>
          <p>
            Playace is not responsible or liable for nonperformance caused by
            telecommunications failures, nonperformance of vendors, fires or
            other acts of nature, strife or acts of political discord, or other
            events outside its reasonable control (each a “Force Majeure”).
            Cancellations of Bookings, whether with or without cause, or caused
            by events outside of your reasonable control, are subject to our
            Cancellation and Refund Policy. Please review it carefully before
            Booking a Space or Vendor Service.
          </p>
          <li className="fw-bold"> No Assignment</li>
          <p>
            You may not assign this Agreement or any rights granted to you,
            including operation or management of your Account, without our prior
            written consent. Any attempt to do so without our prior consent will
            be void. We may assign this Agreement upon notice to you as part of
            a sale or transfer of part or all of our business. Any permitted
            transfer will inure to the benefit of and bind any successors in
            interest.
          </p>
          <li className="fw-bold"> Disclaimers</li>
          <p>
            PLAYACE PROVIDES THE PLATFORM AND SERVICE TO LIST AND BOOK SPACES
            AND VENDOR SERVICES, AND COMMUNICATE WITH OTHER USERS. WE ARE NOT
            RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR PROVIDING, OR FOR THE
            CONDITION OR NATURE OF, ANY SPACE OR VENDOR SERVICES. HOSTS, GUESTS,
            AND VENDORS ARE INDEPENDENT THIRD-PARTIES AND ARE NOT AFFILIATED,
            CONTROLLED BY, OR EMPLOYED BY PLAYACE. HOSTS AND VENDORS SET THEIR
            OWN PRICES, USE THEIR OWN FACILITIES AND RESOURCES, AND MAY
            CONDITION USE OF SPACES OR VENDOR SERVICES AS THEY FEEL IS
            APPROPRIATE FOR THEIR BUSINESS. YOU UNDERSTAND AND AGREE THAT USE OF
            THE PLATFORM AND SERVICES ARE AT YOUR OWN RISK. PLAYACE IS NOT
            RESPONSIBLE FOR PERFORMING AND DOES NOT PERFORM BACKGROUND CHECKS ON
            GUESTS, HOSTS, OR VENDORS (INCLUDING CRIMINAL OR CIVIL CHECKS); DOES
            NOT REVIEW THE CONDITION OF SPACES OR ENSURE THAT THEY COMPLY WITH
            EXISTING LAW, MEET ANY SPECIFIC REQUIREMENTS, OR ARE CONSISTENT WITH
            THEIR DESCRIPTION; AND DOES NOT GUARANTEE PERFORMANCE OF ANY USER OR
            THIRD PARTY. ANY INFORMATION PROVIDED TO YOU REGARDING USERS,
            SPACES, VENDORS OR VENDOR SERVICES IS ONLY PROVIDED TO FACILITATE
            YOUR USE OF THE PLATFORM AND IS PROVIDED “AS IS” AND WITHOUT ANY
            REPRESENTATION OR WARRANTY. PLAYACE DISCLAIMS ALL REPRESENTATIONS
            AND WARRANTIES, EXPRESS AND IMPLIED, THAT ANY INFORMATION PROVIDED
            THROUGH THE PLATFORM IS ACCURATE OR COMPLETE, OR THAT ANY USER,
            SPACE, VENDOR OR VENDOR SERVICE WILL BE PROVIDED TO YOU AS
            DESCRIBED. PLAYACE DISCLAIMS ALL RESPONSIBILITY FOR AND LIABILITY
            RESULTING FROM THE NEGLIGENCE, INTENTIONAL MISCONDUCT, OR CRIMINAL
            ACTIVITY OF ALL USERS OR THIRD PARTIES, OR ANY INJURY OR PROPERTY
            DAMAGE THAT OCCURS TO YOU, THIRD PARTIES, OR PROPERTY WHILE
            ACCESSING OR USING SPACES OR VENDOR SERVICES. THE PLATFORM AND
            SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS
            WITHOUT ANY GUARANTEE OR WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED,
            INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, SUITABILITY FOR YOUR PARTICULAR USE, OR
            NONINFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DISCLAIM THAT
            THE PLATFORM OR SERVICES ARE FREE FROM ERROR OR VIRUSES; THAT THEY
            WILL NEVER CAUSE HARM; THAT THEY MEET OR BE SUITABLE FOR YOUR NEEDS
            OR REQUIREMENTS; THAT THEY ARE ALWAYS AVAILABLE; OR THAT THEY
            ACCURATELY REPRESENT SPACES, HOSTS, GUESTS, VENDORS OR VENDOR
            SERVICES. WE EXPRESSLY DISCLAIM ANY OBLIGATION TO CORRECT ERRORS,
            EVEN THOSE THAT WE ARE AWARE OF, AND MAY CHOOSE TO DISCONTINUE
            OPERATION OF THE PLATFORM OR SERVICE, OR FEATURES OF EITHER, AT ANY
            TIME.
          </p>
          <li className="fw-bold"> Representations and Warranties</li>
          <p>
            By opening your Account, you represent and warrant that (a) you are
            authorized to use the Platform and Services and have authority to
            execute this Agreement; (b) that you have only one Account with us
            and have not had an Account terminated by us previously; (c) all
            information provided to us is accurate and complete; (d) you will
            not use the Platform or Services in violation of Law or the
            Community Guidelines, to commit fraud, to deceive other users, or
            for any other improper purpose; and (e) you are authorized to
            provide any Content to us. When using the Platform or Services as a
            Host, you further represent and warrant that (f) you are permitted
            under applicable Laws to List any Spaces provided; (g) your Listing
            of a Space and the Space itself comply with applicable Laws; and (h)
            you will reasonably facilitate the use of, and not obstruct the use
            of, the Spaces as Booked by Guests. When using the Platform or
            Services as a Guest, you further represent and warrant that (i) you
            will comply with any Booking Agreements; (j) you will not use Spaces
            or Vendor Services in a manner that violates Laws or Community
            Guidelines, or that facilitates the violation of either by third
            parties; and (k) that any payment Credentials provided to us may be
            used as described in this Agreement.
          </p>
          <li className="fw-bold">Indemnities</li>
          <p>
            You will indemnify, defend and hold Playace and its agents,
            directors, shareholders, members, partners, managers, officers,
            employees, information providers, distributors, vendors, attorneys,
            or affiliates (collectively, “Playace Affiliates”) harmless against
            all third-party claims, liabilities, losses, damages, and related
            expenses (including reasonable legal expenses) (collectively,
            “Claims”) arising from or related to (a) provision of your Space
            (for Hosts) or Vendor Service (for Vendors), or use of Spaces or
            Vendor Services (for Guests); (b) Content you provide through the
            Platform; (c) your failure to comply with Laws; (d) your providing
            of information to us that is inaccurate or incomplete, (e) your
            breach of any of your obligations under this Agreement, and (f) any
            contract or other agreement between you and any other user other
            than through the Platform. This indemnification will survive
            termination of this Agreement.
          </p>
          <li className="fw-bold">Limitations of Liability</li>
          <p>
            EXCEPT TO THE LIMITED EXTENT OF THE PLAYACE FEES (AS DEFINED BELOW)
            UNDER NO CIRCUMSTANCES OR ANY LEGAL THEORY WILL PLAYACE OR ITS
            AGENTS, DIRECTORS, SHAREHOLDERS, MEMBERS, PARTNERS, MANAGERS,
            OFFICERS, EMPLOYEES, INFORMATION PROVIDERS, DISTRIBUTORS, VENDORS,
            ATTORNEYS, OR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            DAMAGES (WHETHER DIRECT, INDIRECT, GENERAL, COMPENSATORY,
            INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY OR
            OTHERWISE), INJURY, CLAIM OR LIABILITY OF ANY KIND OR CHARACTER
            BASED UPON OR ARISING FROM YOUR USE OF OR INABILITY TO USE THE
            PLATFORM OR SERVICES, OR SPACES OR VENDOR SERVICES, EVEN IF WE HAVE
            BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IF YOU ARE DISSATISFIED
            WITH THE PLATFORM OR SERVICES, OR ANY CONTENT CONTAINED THEREIN,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE PLATFORM
            AND SERVICES. AS USED HEREIN, “PLAYACE FEES” SHALL MEAN THE AMOUNT
            PAID BY YOU TO PLAYACE, EXCLUDING ANY AMOUNTS THAT WERE PAID OR
            PAYABLE TO HOSTS OR VENDORS, FOR USE OF THE PLATFORM OR SERVICES.
            THE LIMITATION OF LIABILITY SET FORTH ABOVE APPLIES TO THE EXTENT
            PERMITTED BY LAW.
          </p>
          <li className="fw-bold"> Additional Terms for Users outside INDIA</li>
          <p>
            We may allow for use of the Service to List Spaces outside of INDIA.
            Where this is permitted, you will be required to ensure that your
            use of the Service, including Listing or Booking, as applicable
            comply with all Laws specific to your country, province, or region.
            European users may be required to provide information to assist with
            our collection of VAT or other indirect Taxes, or evidence of your
            exemption such Taxes.
          </p>
          <li className="fw-bold">Entire Agreement; Interpretation</li>
          <p>
            This Agreement together with any Booking Agreement incorporates the
            Community Guidelines, Fees Overview, Privacy Policy, Cancellation
            and Refund Policy, and any other policies referenced therein, which
            constitute the entire agreement between you and Playace governing
            your use of the Platform or Services. This Agreement supersedes all
            prior understandings or agreements between you and Playace. As
            between you and Playace, this Agreement controls over any
            conflicting terms in a Booking Agreement except where expressly
            stated otherwise and agreed upon in writing between the parties.
            <br />
            As used in this Agreement, “including” or similar words mean
            “including but not limited to”; “or” may be read as “either… or…” or
            “both… and…”; and “such as,” “specifically,” “for example,” or
            similar words identify non-exclusive lists of examples. Headings and
            ordering used in this Agreement are provided for your convenience
            and do not affect the interpretation of related provisions. Any
            monetary amounts described in this agreement will be in INR and “₹”
            will be read to mean Indian Rupees. Except as provided in Section
            5.1 above, this Agreement may only be modified through a written
            agreement that is signed by both you and Playace. Where any part of
            this Agreement is found to be invalid or unenforceable, it will be
            reformed or reinterpreted through force of Law as minimally required
            to accomplish the objectives while maintaining the original intent
            of such provision.
          </p>
        </ol>
      </ol>
      <h4 Align="center">Playace Fees Overview</h4>
      <p>
        This Playace Fees Overview specifies the Fees owed by you and collected
        by Playace when Listing or Booking on the Platform, or using other
        Services. This Fees Overview specifically identify Fees owed by Guests,
        Hosts, or Vendors, and how Fees are paid to Playace. The Fees Overview
        is part of and uses words and phrases defined in the Playace Services
        Agreement. All Users acknowledge and agree that all payments shall be
        made in accordance with this Fees Overview. If you have any questions
        about the Fees Overview, please contact us.
      </p>
      <ol>
        <li>
          <span className="fw-bold">Guest Fees</span>
        </li>
        <p>
          All the Fees owed for the Booking will be presented prior to your
          acceptance, which acceptance will be subject to our Cancellation and
          Refund Policy. We may authorize a hold for the amount of the Fees to
          your credit card or other payment methods linked to your Account when
          you request a Booking. When a Booking is accepted by a Host or Vendor,
          we will charge your Account for the full amount of the Fees. Fees
          include amounts ultimately paid to Hosts or Vendors, and processing
          Fees payable to us. Host Fees will include the total amount for the
          hours Booked, and may include Cleaning Fees in their hourly rate or as
          a separate item. Vendor Fees may be listed hourly, by the service, or
          as a fixed amount depending on the service provided (for instance, a
          photographer may charge hourly and a caterer may charge by what is
          ordered on a per-person basis). Taxes will also be identified
          separately. If you update a Booking, we may adjust the Host or Vendor
          Fees accordingly which may result in either additional amounts owed or
          a refund of amounts paid. A processing Fee of 10% is included in all
          charges to Guests.
        </p>
        <li>
          <span className="fw-bold">Host Fees</span>
        </li>
        <p>
          Playace presently does not charge the Hosts any fees for listing their
          space on the platform. However Playace may charge the hosts in the
          future. In such a case, Playace will notify the hosts and update the
          fees overview. During the time, when hosts will be charged a fee by
          Playace, When a Booking is completed, a service Fee will be retained
          by Playace from Hosts’ payouts as a compensation to Playace for
          providing the Services and use of the Platform. The fee will be based
          on the subtotal for payment for Booking the Space for the agreed upon
          period including any required Host provided Vendor Services, Cleaning
          Fees and Additional Fees, if applicable (a “License Charge”) and is
          retained at the time a Host’s payout is initiated.
          <br />
          The payout amount is the License Charge minus any applicable Fees,
          Taxes, or Fines. Playace will normally initiate payouts to Hosts
          within 7 days after a Booking is completed and payouts to Vendors will
          depend on the Vendor Service provided and agreed terms. We may
          withhold a payout if a dispute is initiated between a Guest and a Host
          or Vendor, or as otherwise permitted by the Services Agreement.
          Payouts of Additional Fees or for damages, or resulting from a dispute
          will be paid within a reasonable time after settlement of a dispute.
          Timing of a payout may also be modified where the Booking is updated,
          rescheduled, or cancelled.
        </p>
        <li>
          <span className="fw-bold">Vendor Fees</span>
        </li>
        <p>
          Playace may choose to work with Vendors that provide services to hosts
          and guests. Any Fees charged to Vendors are covered in a separate
          agreement that defines the relationship between Playace and each
          Vendor.
        </p>
        <li>
          <span className="fw-bold">Concierge Services Fees</span>
        </li>
        <p>
          You may elect to use Concierge Services to assist you with Booking a
          Space or non-Host provided Vendor Service as a Guest, or Listing a
          Space as Host. Concierge Services depend on your unique needs and may
          include identifying specific Vendors (such as catering or furniture
          rental), scouting Spaces for your specific needs, or assistance in
          event planning. Fees for Concierge Services are in addition to the
          Fees for the Space or Vendor Services and are charged at the time they
          are confirmed, or are set at a fixed price when explicitly stated
          (such as Concierge Services for Listing a Space as a Host). The
          specific Fees for Concierge Services will be presented before you
          confirm a Booking.
        </p>
        <li>
          <span className="fw-bold">Other Fees</span>
        </li>
        <p>
          Cleaning Fees. <br />
          Cleaning Fees for a Booking may be included in the hourly rate or as a
          separate line item. Any cleaning Fees that are separately charged will
          be identified before you confirm a Booking.
          <br />
          Additional Fees.
          <br /> Where you exceed the use limitations of a Space, additional
          Fees may apply. For overtime use of a Space, the Additional Fees will
          be based on the hourly rate for the Space, at one and half times the
          scheduled rate, and will be billed in 30-minute increments. For
          overintensive use of a Space (e.g., materially exceeding the agreed
          number of attendees), in our sole discretion we may impose an
          additional use charge. We may bill any Additional Fees when first
          notified that the agreed-upon time for use was exceeded and may charge
          any payment method tied to the Guest’s Account. Any disputes relating
          to Additional Fees are subject to the dispute resolution process
          identified in the Service Agreement. Fines for Damages and Other
          Circumstances. While we try to avoid these situations, occasionally
          the terms or expectations of a Booking are not met and Fines may be
          owed by a user. This may be caused by damage to or misuse of a Space
          or Amenities, inappropriate use of Vendor Services, excess garbage
          left at a Space, violations of the Booking Agreement or the Community
          Guidelines, insufficient funds or unauthorized use of a payment
          account, or other claims. Where any Fines are owed, you agree that we
          may immediately charge any payment method linked to your Account.
          Cancellations. All Fees are subject to our Cancellation and Refund
          Policy.
        </p>
      </ol>
      <h4 Align="center">Playace Community Guidelines</h4>
      <p>
        Maintaining Playace as a healthy marketplace requires users to showcase
        their spaces, plan their events, and interact with other users in a
        cordial and professional manner. We rely on you to follow these
        guidelines to ensure the safety and well-being of all Playace users,
        including yourself, when posting Content, communicating with other
        users, using Spaces, and making or accepting payments. These guidelines
        are a non-exhaustive list intended to give assurance to all our users.
        We take violations of these Community Guidelines seriously and may
        suspend or terminate an Account for ongoing or serious violations. We
        may also moderate, remove, or edit any Content that violates these
        Community Guidelines or if we believe it adversely affects the integrity
        of Playace or other users, and may add and remove elements of these
        Community Guidelines over time by posting an updated version to our
        website.
        <br />
        These Community Guidelines are part of and use words and phrases defined
        in the the Playace Services Agreement. If you have any questions about
        these Community Guidelines or if you believe that a user has violated
        these Community Guidelines, please contact us. You must agree to these
        Community Guidelines, as part of the Playace Services Agreement, to use
        of the Platform and Services. Please read them carefully and only use
        the Platform or Services if you agree to abide by them.
      </p>
      <div className="fw-bold">Hosts will:</div>

      <ul>
        <li>
          Comply with any Laws, including local zoning, permitting, or tax laws.
          Zoning and use restrictions are in place to protect people, including
          your guests. You know your Space best and Guests trust that your Space
          is up to code, properly licensed, and safe.
        </li>
        <li>
          Accurately represent the nature, condition, and pricing of Spaces.
          Most Guests will not have seen your Space before their event. It’s
          important that you give them an accurate idea of what to expect. While
          we know you want to put your best foot forward, you should be
          realistic and honest about your Space. Guests should also have a
          realistic idea of the actual Booking costs and clearly list any
          services you provide for an additional cost. You may not list prices
          lower than what you intend to charge.
        </li>
        <li>
          Only offer Spaces that you’re permitted to Book. If you don’t own your
          Space or don’t have permission to List it, then you are not permitted
          to List it on the Platform. If you are renting, and not the owner or
          manager of a Space, you may need to have approval from the owner,
          manager, or other party of interest before you List it. You are
          welcome to List multiple Spaces but may not offer Spaces to Guests
          that are not Listed
        </li>
      </ul>
      <div className="fw-bold">Guests will:</div>
      <ul>
        <li>
          Use Spaces with respect and as described to Hosts. Hosts take pride in
          their Spaces and trust that they will be respected by Guests. It is
          important that you are honest and do not misrepresent the intended use
          of a Space when Booking. It is up to the Hosts to determine how they
          want (or do not want) their Spaces used.
        </li>
        <li>
          Never store, transport, or possess hazardous material in a Space. This
          should go without saying but hazardous material can harm people and do
          permanent damage to Spaces. Bringing hazardous materials to a Space is
          not only dangerous but may be illegal, and you could face significant
          civil liability or criminal action for doing so.
        </li>
      </ul>
      <div className="fw-bold">Everyone must:</div>
      <ul>
        <li>
          Communicate with other users honestly. People trust Listings, comments
          about Spaces, and your communication with them. It’s important that
          you are clear and honest when you post. You may not use the Services
          to deceive or mislead people, post false or inaccurate statements,
          open multiple Accounts, or misrepresent who you are or your
          affiliations.
        </li>
        <li>
          Transact honestly. Users may only use the Platform to transact as
          provided for legitimate Bookings. Use of the Platform to conduct
          payment or other transactions offline, or attempting to transact when
          you know or believe the payment or payout is invalid, may result in
          suspension or termination of your Account or Fines.
        </li>
        <li>
          Respect other people’s information: You are only allowed to use the
          Content provided through the Platform as intended. You may not use or
          misuse Content from the Platform, images, or descriptions outside of
          the Platform. You may not harvest, scrape, or collect other
          information about our other users, such as names, phone numbers,
          physical addresses, email addresses or any other Content that is not
          publicly displayed in search results pages prior to a Booking
          confirmation.
        </li>
        <li>
          Use the Platform as Intended. The Platform is meant for Hosts to List
          and Guests to Book Spaces and third party or Host provided Vendor
          Services. You are not allowed to use the Platform to promote products
          or services other than your Space. You may not send unwanted messages
          or spam, promote use of a Playace competitor, or use the Platform to
          communicate with users with no intention of Listing or Booking on the
          Platform. You may not Book Spaces in exchange for other services or
          goods in lieu of payment unless previously authorized by Playace. No
          one may:
        </li>
        <li>
          Discriminate against other users. Discrimination against any other
          users based on their race, sex, gender, ethnicity, national origin,
          sexual orientation, disability, or any other legally protected class
          is illegal and not permitted on the Platform. If we believe that you
          have refused to transact with another user based on any of these, we
          may suspend or terminate your account immediately.
        </li>
        <li>
          Encourage or endorse illegal or dangerous behavior. You may not post
          Content that endorses or promotes illegal or harmful activities,
          including obscene content or encouraging activity that may lead to
          other users harm. While use of marijuana is or may become legal in
          some jurisdictions, it remains prohibited under federal law and may
          not be grown, sold, distributed or used in any Space that is Booked on
          the Platform.
        </li>
        <li>
          Harm, intimidate, or threaten Users. We’ve built a Platform that we
          are proud of and that our users trust, and work hard to keep it that
          way. You are prohibited from doing anything that may harm or
          intimidate users, or interfere with their use or operation of the
          Platform. This includes threatening or harassing other users; posting
          undeserved or disproportionate reviews, or reviews that do not reflect
          your experience; attempting to extort other users for discounts or
          favorable reviews; or threatening legal action.
        </li>
        <li>
          Share personal or dispute information. For your protection, you may
          not share personal or sensitive information about yourself. This
          includes your home address, phone number, payment information, or
          other sensitive information that could lead to fraud or identity
          theft. This may be a safety risk to you and others. You may also not
          post any details about an ongoing dispute between you and another
          user.
        </li>
        <li>
          Harm, intimidate, harass, or threaten Playace or our brand. Anyone
          threatening Playace or our employees will be removed from the Platform
          and reported to law enforcement. You may not engage in behavior on the
          Platform or use Playace marks or logos in a manner that violates the
          Playace Service Agreement or harms, dilutes, or misuses our brand.
        </li>
      </ul>
    </div>
  );
}
