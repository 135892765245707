import {
    GET_PARTY_LIST_START,
    GET_PARTY_SUCCESS,
    GET_PARTY_FAILURE,
} from '../actions/types';

const initialState = {
    "data": [],
    "loading": true,
    "errors": []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PARTY_LIST_START:
            return {
                ...state,
                "loading": true
            }

        case GET_PARTY_SUCCESS:
            return {
                ...state,
                "loading": false,
                "data": action.payload
            }

        case GET_PARTY_FAILURE:
            return {
                ...state,
                "loading": false,
                "errors": action.payload
            };

        default: return { ...state }
    }
}