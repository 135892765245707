import React, { useRef, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { useHistory } from "react-router-dom";
import Img from "react-cool-img";
import styles from "./HostAParty.module.scss";
import { useDispatch, useSelector } from "react-redux";
import PlanParty from "../../../assets/hosting_plan_party.png";
import WelcomeGuests from "../../../assets/hosting_welcome_guests.png";
import GetPaid from "../../../assets/hosting_get_paid.png";
import ThankyouImage from "../../../assets/request_sent.png";

import PlanningOverview from "./PlanningOverview";
import Auth from "../../modules/auth/Auth";
import { useEffect } from "react";
import playace from "../../../assets/logo_white.png";
import "./HostAParty.css";
import Faq from "../Home/Faq";
import HousePartyModal from "./HostPartyModal";
import { fetchEventsByHostId } from "../../../api/requests";
import { RenderUserObj } from "../../helpers";
import HostParties from "./HostParties";
import { HostPartySteps } from "../../../assets/host-party-steps/HostPartySteps";
import host1 from "../../../assets/hostImages/image 13.png";
import host2 from "../../../assets/hostImages/image 14.png";
import host3 from "../../../assets/hostImages/image 15.png";
import host4 from "../../../assets/hostImages/image 16.png";
import host5 from "../../../assets/hostImages/image 18.png";
import host6 from "../../../assets/hostImages/image 19.png";
import host7 from "../../../assets/hostImages/image 20.png";
import host8 from "../../../assets/hostImages/image 65.png";
import host9 from "../../../assets/hostImages/image 66.png";
import host10 from "../../../assets/hostImages/image 67.png";
import host11 from "../../../assets/hostImages/image 68.png";

import step1 from "../../../assets/host-party-steps/step1.png";
import step2 from "../../../assets/host-party-steps/step2.png";
import step3 from "../../../assets/host-party-steps/step3.png";
import step4 from "../../../assets/host-party-steps/step4.png";
import step5 from "../../../assets/host-party-steps/step5.png";
import step6 from "../../../assets/host-party-steps/step6.png";
import { getLoader } from "../../../utils/utils";

import { IconUserScan, IconSparkles, IconVideo, IconRosetteDiscountCheck, IconMoodSmileBeam, IconNotes } from '@tabler/icons-react';
import { updateIsHost } from "../../../actions/authActions";

export default function HostAParty() {
  const sectionRef = useRef(null);
  const [isScrollBlocked, setIsScrollBlocked] = useState(true);
  const [hasScrolledOnce, setHasScrolledOnce] = useState(false);
  let [showLoginModal, setShowLoginModal] = useState(null);

  let userObj = RenderUserObj(useSelector((state) => state.auth.data));
  const history = useHistory();
  const [isShowHostRequestModel, setShowHostRequestModel] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleShowModal = () => {
    if (!isAuthenticated) {
      setShowLoginModal(true);
    } else {
      setShowHostRequestModel(true);
    }
  };
  const handleCloseModal = () => setShowHostRequestModel(false);

  const handleClose = () => {
    setShowLoginModal(false);
  };

  const handleLogin = () => {
    setShowHostRequestModel(true);
  }

  const imagesArray = [host1, host2, host3, host4, host5];
  const imagesArray2 = [host6, host7, host8, host9, host10, host11];
  const [hostedParties, setHostedParties] = useState(null);

  const callGetHostParites = async () => {
    setLoading(true);
    const response = await fetchEventsByHostId(userObj?.userId);
    setLoading(false);
    if (response?.status === "success") {
      console.log(response);
      setHostedParties(response.data);
    }
  };

  const sectionRefs = useRef([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const handleScroll = () => {
    const { scrollTop } = document.documentElement || document.body;

    // Calculate the index of the active section based on scroll position
    let index = 0;
    sectionRefs.current.forEach((ref, i) => {
      const sectionTop = ref.offsetTop;
      if (scrollTop >= sectionTop) {
        index = i;
      }
    });

    // Update active section index
    setActiveSectionIndex(index);
  };

  useEffect(() => {
    callGetHostParites();
  }, [userObj?.userId]);

  useEffect(() => {
    dispatch(updateIsHost(true));
  }, []);

  if (loading) {
    return getLoader();
  }

  return (
    <>
      {hostedParties ? (
        <HostParties
          parties={hostedParties}
          refreshPage={callGetHostParites}
          isShowHostRequestModel={isShowHostRequestModel}
          setShowHostRequestModel={setShowHostRequestModel}
        />
      ) : (
        <>
          <HousePartyModal show={isShowHostRequestModel} handleClose={handleCloseModal} />
          <Default>
            <Modal
              size="lg"
              show={showLoginModal}
              onHide={() => setShowLoginModal(false)}
              backdrop="static"
              dialogClassName="modal-dimensions borderandWidth"
              centered
            >
              <Auth handleClose={handleClose} handleLogin={handleLogin} />
            </Modal>
          </Default>

          <Mobile>
            <Modal
              size="lg"
              show={showLoginModal}
              onHide={handleClose}
              backdrop="static"
              centered
            >
              <Auth handleClose={handleClose} handleLogin={handleLogin} />
            </Modal>
          </Mobile>

          <section className="hostLandingHero">
            <div className="spotlight" />

            <div className="container">
              <div className="video-marquee-container-vertical">
                <div className="video-marquee-column">
                  <div className="video-marquee-vertical">
                    {imagesArray.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                    {imagesArray.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                    {imagesArray.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                  </div>
                </div>
                <div className="video-marquee-column rightzero">
                  <div className="video-marquee-vertical">
                    {imagesArray2.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                    {imagesArray2.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                    {imagesArray2.map((image, index) => (
                      <img src={image} alt="" />
                    ))}
                  </div>
                </div>
              </div>

              <div className="hostHeroCont">
                <h1>Why just attend? Host regularly and earn up to ₹40,000!</h1>
                <p>T & C Applied</p>
                <button
                  class="btn btn-reg btn-primary customizeBtn"
                  onClick={handleShowModal}
                >
                  <a class="iconSpace">
                    Let’s Host Party
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-hand-love-you"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M11 11.5v-1a1.5 1.5 0 0 1 3 0v1.5"></path>
                      <path d="M17 12v-6.5a1.5 1.5 0 0 1 3 0v10.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
                      <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
                      <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
                    </svg>
                  </a>
                </button>
              </div>
            </div>
          </section>

          <section className="topFakeHead_cont" style={{ marginTop: "5rem" }}>
            <Container>
              <div className="housePartyInfo">
                <h3>Hosting your first house party?</h3>
                <p>Here’s a quick guide to help you</p>
              </div>

              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "#9552F21A" }}
                    >
                      <IconSparkles size={24} />

                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 1</p>
                  </div>
                  <h4>Submit Your Hosting Request</h4>
                  <p>
                    Send us a hosting request on Playace.co. One of our friendly
                    representatives will reach out to get to know you better. We
                    love to connect with our hosts!
                  </p>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step1} />
                  </div>

                  {/* <div className="sBlock"> </div>
                  <div className="sBlock"></div>
                  <div className="sBlock"></div>
                  <div className="sBlock"></div> */}
                </div>
              </div>
              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "rgba(64, 228, 132, 0.1)" }}
                    >
                      <IconUserScan size={24} color="#40E484" />
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 2</p>
                  </div>
                  <h4>Verification and Security</h4>
                  <p>
                    Upload your government ID to our secure verification system
                    on the website. This takes just 2 minutes. Your privacy is
                    our priority; your documents are confidential.
                  </p>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step2} />
                  </div>
                </div>
              </div>
              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "rgba(251, 86, 7, 0.1)" }}
                    >
                      <IconVideo size={24} color="#FB5607" />
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 3</p>
                  </div>
                  <h4>Walkthrough Video</h4>
                  <p>
                    Send us a walkthrough video of your party room, ideally a
                    living room or common area. This helps us assess the space
                    and the vibe you're aiming for.
                  </p>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step3} />
                  </div>
                </div>
              </div>
              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "rgba(149, 82, 242, 0.1)" }}
                    >
                      < IconRosetteDiscountCheck size={24} color='#3A86FF' />
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 4</p>
                  </div>
                  <h4>Become a Verified Host</h4>
                  <p>
                    Once the steps above are complete, congrats! You're now a
                    verified Playace.co host. Our team will get in touch to
                    schedule your first house party.
                  </p>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step4} />
                  </div>
                </div>
              </div>
              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "rgba(255, 231, 50, 0.1)" }}
                    >
                      <IconNotes size={24} color='#FFE732' />
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 5</p>
                  </div>
                  <h4>Party Planning</h4>
                  <p>
                    In this phase, we’ll determine the theme, set the price
                    point, and decide on the amenities. Our team will guide you
                    to ensure everything is tailored for maximum guest bookings.
                  </p>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step5} />
                  </div>
                </div>
              </div>
              <div className="scrollBlockCont">
                <div className="houseParty_leftInfo">
                  <div className="flexyStep">
                    <div
                      className="smallIcon"
                      style={{ backgroundColor: "rgba(253, 84, 216, 0.1)" }}
                    >
                      <IconMoodSmileBeam size={24} color="#FD54D8" />
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                    </svg> */}
                    </div>
                    <p>Step 6</p>
                  </div>
                  <h4>Guest Verification</h4>
                  <p>
                    As your party goes live, you’ll start receiving booking
                    requests. All guests are verified through multiple layers:
                    phone number, government ID, face match, and
                    tele-verification. Only verified guests receive your contact
                    details and the venue address 2 hours before the party.
                  </p>

                  <strong>
                    With these steps, you're all set to host an unforgettable
                    house party on Playace.co!
                  </strong>
                </div>

                <div className="scrollBlock_overflow" ref={sectionRef}>
                  <div className="sBlock">
                    <img src={step6} />
                  </div>
                </div>
              </div>

              {/* <div>
                {HostPartySteps.map((step, index) => (
                  <div
                    key={index}
                    className={`scrollBlockCont ${
                      activeSectionIndex === index ? "active" : ""
                    }`}
                    ref={(el) => (sectionRefs.current[index] = el)}
                  >
                    <div className="houseParty_leftInfo">
                      <div
                        className="smallIcon"
                        style={{ backgroundColor: step.backgroundColor }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                        </svg>
                      </div>
                      <h4>{step.title}</h4>
                      <p>{step.description}</p>
                    </div>

                    <div className="scrollBlock_overflow">
                      <div className="sBlock">
                        <img src={step.image} alt={step.alt} />
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
            </Container>
          </section>

          <section className="faqCont">
            <Faq isGuest={false} />
          </section>
        </>
      )}
    </>
  );
}

