import React from 'react';
import { Default, Mobile } from '../helpers/Breakpoints';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Input from '../modules/common/FormInput';

import Illustration from '../assets/illustration_with_logo copy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function Contact() {
    return (
        <>
            <Default>
                <Container style={{ margin: "7rem auto 0 auto" }}>
                    <Row>
                        <Col md={6}>
                            <h3>Hey,</h3>
                            <small className="d-block" style={{ fontSize: "14px" }}>
                                We’d love to receive a call or an e-mail from you if you have something to talk about House Parties!
                                (Even if it’s remotely related)
                            </small>
                            <Input placeholder="Name" classes="mb-3 mt-3 w-100" />
                            <Input placeholder="Phone number" classes="mb-3 mt-3 w-100" />
                            <Input placeholder="Email ID" classes="mb-3 mt-3 w-100" />
                            <Input placeholder="Message" classes="mb-3 mt-5 w-100" />
                            <Button variant="primary" size="md" className="text-white rounded-lg py-2">Submit</Button>
                            <div className="mt-5 align-items-center">
                                <FontAwesomeIcon icon={faWhatsapp} size="3x" />
                                <h3 className="d-inline mx-3"> +919820329205</h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="text-right">
                                <img className="img-fluid w-75" src={Illustration} alt="Contact us" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="bg-gray py-4 mTop">
                    <Container>
                        <h3>Want to work with us?</h3>
                        <small className="d-block" style={{ fontSize: "14px" }}>See the job openings below and contact us</small>

                        <div className="mt-5 border-bottom">
                            <h4 className="text-primary">Technical Head</h4>
                            <small className="d-block mt-3 mb-5" style={{ fontSize: "14px" }}>Write description here about the job.</small>
                        </div>

                        <div className="mt-5 border-bottom">
                            <h4 className="text-secondary">Graphic designer</h4>
                            <small className="d-block mt-3 mb-5" style={{ fontSize: "14px" }}>Write description here about the job.</small>
                        </div>
                    </Container>
                </div>
            </Default>
            <Mobile>
                <div style={{ margin: "5rem 0 5rem 0", padding: "0 1.5rem" }}>
                    <div className="text-center">
                        <img className="img-fluid w-75" src={Illustration} alt="Contact us" />
                    </div>
                    <h3>Hey,</h3>
                    <small className="d-block" style={{ fontSize: "14px" }}>
                        We’d love to receive a call or an e-mail from you if you have something to talk about House Parties!
                        (Even if it’s remotely related)
                            </small>
                    <Input placeholder="Name" classes="mb-3 mt-3 w-100" />
                    <Input placeholder="Phone number" classes="mb-3 mt-3 w-100" />
                    <Input placeholder="Email ID" classes="mb-3 mt-3 w-100" />
                    <Input placeholder="Message" classes="mb-3 mt-5 w-100" />
                    <Button size="md" className="btn-block text-white rounded-lg py-2 text-black-50" style={{ border: "1px solid #4a4a4a", background: "transparent" }}>SUBMIT</Button>
                </div>
            </Mobile>
        </>
    );
}