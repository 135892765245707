import React from "react";
import { Container } from "react-bootstrap";

export default function Disclaimer() {
  return (
    <Container style={{ marginTop: "7rem", marginBottom:"15rem" }} className="extraFontsCont" >
      <h4 align="center">DISCLAIMER</h4>
      <p>
        Playace.co. site, including all content, software, functions, materials
        and information made available on or accessed through this website, is
        provided "AS IS" to the fullest extent permissible by law, we make no
        representations or warranties of any kind whatsoever for the content on
        the site or the materials, information and functions made accessible by
        the software used on or accessed through the site, for any services or
        for any breach of security associated with the transmission of sensitive
        information through the site, even if Playace.co. becomes aware of any
        such breaches. Additionally, we expressly repudiate any express or
        implied warranties, including, without limitation, non-infringement,
        merchantability, or accuracy. We do not warrant that the functions
        contained on the site or any materials or content contained therein will
        be uninterrupted or error free, that blemishes will be corrected, or
        that the site or the server that makes it available is free of viruses
        or other harmful components. You acknowledge and approve that any
        communication to and from this site is not confidential and your
        communications or user-contributed content may be read or captured by
        others. You further acknowledge and agree that by submitting
        communications or user-contributed content to us and by posting
        information on the site, including house party listings or attending a
        house party, no confidential, contractually implied or other
        relationship is created between you and us other than pursuant to these
        terms.
      </p>
      <p>
        You acknowledge and agree that you will not hold or seek us responsible
        for the content provided by any user, including, without limitation, any
        translation thereof, and you further acknowledge and agree that we are
        not a party to any house party organizer. We have no control over and do
        not guarantee (other than pursuant to any guarantee that may be offered
        on the site) the safety of any transaction or the truth or accuracy of
        any listing or other content provided on the site.
      </p>
    </Container>
  );
}
