import React, { useEffect, useState } from "react";
import Img from "react-cool-img";

import styles from "./Party.module.scss";

import moment from "moment";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  cancelPaymentRefundByEvent,
  fetchEventsByHostId,
  fetchUpcomingPartiesAsHost,
  fetchUser,
} from "../../../../api/requests";
import { Loader, RenderDate } from "../../../helpers";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { Container, Modal } from "react-bootstrap";

export default function AsHost({ token, ...props }) {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];
  const { hostId } = useParams();

  const authState = useSelector((state) => state.auth);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    let userDetails = fetchUser("users", userObj?.token, userObj?.userId).then(
      (response) => {
        setUserData(response?.data);
      }
    );

    let fetchEventsByHostIds = fetchEventsByHostId(
      "fetchEventsByHostId",
      userObj?.userId,
      null
    ).then((response) => {
      setData(response);
    });
  }, []);

  const fetchEventData = () => {
    let fetchEventsByHostIds = fetchEventsByHostId(
      "fetchEventsByHostId",
      userObj?.userId,
      null
    ).then((response) => {
      response.data.sort((a, b) => {
        const dateA = new Date(a.eventDetails?.eventDate);
        const dateB = new Date(b.eventDetails?.eventDate);
        return dateB - dateA;
      });

      setData(response);
    });
  };

  const containerStyles = {
    maxHeight: "400px",
    overflowX: "auto",
  };

  return (
    <div className={styles["as-a-host"]}>
      <p className="fw-bold text-uppercase text-primary">As a host</p>
      <div>
        {/* {isLoading && (
          <div className={styles["loading-overlay"]}>
            <Loader />
          </div>
        )} */}
        {data && data?.data?.length === 0 && <p>No parties hosted.</p>}
        <div style={containerStyles}>
          {data &&
            data?.data?.map((party) => {
              return (
                <PartyCardWithImage
                  key={v4()}
                  {...party}
                  fetchEventData={fetchEventData}
                  userData={userData}
                />
              );
            })}
        </div>
        <Link to="/host-party" className="btn btn-primary fw-bold mt-5">
          ADD A PARTY
        </Link>
      </div>
    </div>
  );
}

function PartyCardWithImage(props) {
  const userData = props.userData;

  const partyDateTime = props.startTime;
  const partyDate = new Date(props.eventDate);

  const currentDateTime = new Date();
  const currentTime = currentDateTime.toTimeString().slice(0, 5);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const [ticketModal, setTicketModal] = useState(false);
  const [response, setResponse] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [reason, setReason] = useState("");

  const handleReason = (e) => {
    setReason(e.target.value);
  };
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedReason, setSelectedReason] = useState('');
  // const [reasons] = useState([
  //   'Not feeling well',
  //   'Change in plans',
  //   'Bad weather',
  //   'Other',
  // ]);

  const location = useLocation();
  const history = useHistory();

  const handleCancelClick = () => {
    setTicketModal(true);
  };

  const handleTicketModal = () => {
    setTicketModal(false);
  };

  // const handleConfirmCancel = () => {
  //   if (selectedReason === '') {
  //     // You can show an error message or prevent cancellation without a reason.
  //     return;
  //   }

  //   // Handle the cancellation logic here, e.g., send a request to cancel the event
  //   // along with the selected reason.

  //   // After handling the cancellation, close the modal and reset the selected reason.
  //   setIsModalOpen(false);
  //   setSelectedReason('');
  // };

  const formatDate = (inputDate) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleContinue = () => {
    const formattedDate = formatDate(props.eventDate);

    let messageBody = `Event Cancellation Alert!
Following Host has requested to cancel an event.
*Event Name:* ${props.title}
*Host Name:* ${userData.fullName}
*Phone Number:* ${userData.phone}
*Event Date:* ${formattedDate}
*Reason:* ${reason}`;

    let obj = {
      eventId: props.eventId,
      reason: messageBody,
    };
    setIsSubmitted(true);
    cancelPaymentRefundByEvent(userObj.token, obj)
      .then((response) => {
        if (response.status == "success") {
          setResponse("Event Cancelled Successfully.");
          props.fetchEventData();
          setTimeout(() => {
            setResponse(null);
            setTicketModal(false);
            setIsSubmitted(false);
          }, 2000);
        } else {
          setResponse("Something went wrong, try again in some time.");
          setIsSubmitted(false);
        }
      })
      .catch((error) => {
        setResponse("Something went wrong, try again in some time");
        setIsSubmitted(false);
      });
  };

  const formatTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };
  // const partyDateTime = new Date(props?.eventDate)
  // const currentDateTime = new Date();

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // const handleSelectReason = (reason) => {
  //   setSelectedReason(reason);
  // };

  const handleOnClick = () => {
    if (props.status == "live") {
      history.push(`/parties/${props.eventId}`);
    }
  };

  const handleOnClickImage = () => {
    if (props.status == "live") {
      history.push(`/parties/${props.eventId}`);
    }
  };

  function formatDateByme(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  }

  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${styles["card-with-img"]} shadow`}
    >
      <div
        className={styles["card-with-img__img"]}
        onClick={handleOnClickImage}
      >
        <Img
          style={{
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            objectFit: "cover",
          }}
          src={props?.images[0]}
          alt="party attending"
        />
      </div>
      <div
        className={`${styles["card-with-img__content"]} padding-card py-3 pr-3 ml-3`}
      >
        <div className="d-flex justify-content-between" onClick={handleOnClick}>
          <div>
            <h5 className="fw-bold first-title">{props.title}</h5>
            <small className="m-0 text-muted location">
              {RenderDate(props?.eventDate)} | {formatTime(props.startTime)}-
              {formatTime(props.endTime)}
            </small>
            <br />
            <small className="m-0 text-muted location">
              {/* USE CITYNAME and LOCALITY */}
              {props.location && props.location}
            </small>
          </div>
          <div>
            {/**Price field not available, update when price is available, same for  ticket count*/}
            <h5 className="fw-bold mr-1 price">&#8377; {props.amount}</h5>
          </div>
        </div>
        {/* {props?.isOnHold && props?.status == "hold" ? (
            <div className="d-flex justify-content-end status-css">
            {(partyDate < currentDateTime) ? ( <p className="fw-bold m-0 text-success">Completed</p>) : (<p className="fw-bold m-0" style={{ color:'red'}}>
            Cancel request sent
            </p>)}
          </div>
          ) : (
            <div className="d-flex justify-content-end status-css">
              {(partyDate < currentDateTime) ? ( <p className="fw-bold m-0 text-success">Completed</p>) : (<p className="fw-bold m-0" style={{ color:'red', cursor:"pointer" }} onClick={handleCancelClick}>
                Cancel
              </p>)}
            </div>
          )} */}
        {props?.isOnHold && props?.status === "hold" ? (
          <div className="d-flex justify-content-end status-css">
            {/* {(formatDateByme(partyDate) <= formatDateByme(currentDateTime)) ? (
      <p className="fw-bold m-0 text-success">Completed</p>
    ) : (
      <p className="fw-bold m-0" style={{ color: 'red' }}>
        Cancel request sent
      </p>
    )} */}
            {partyDate.getTime() > currentDateTime.getTime() ? (
              <p className="fw-bold m-0" style={{ color: "red" }}>
                Cancel request sent
              </p>
            ) : formatDateByme(partyDate) === formatDateByme(currentDateTime) &&
              partyDateTime > currentTime ? (
              <p className="fw-bold m-0" style={{ color: "red" }}>
                Cancel request sent
              </p>
            ) : (
              <p className="fw-bold m-0 text-success">Completed</p>
            )}
          </div>
        ) : props?.status === "cancel" ? (
          <div
            className="fw-bold m-0 d-flex justify-content-end"
            style={{ color: "red" }}
          >
            Event Canceled
          </div>
        ) : (
          <div className="d-flex justify-content-end status-css">
            {partyDate.getTime() > currentDateTime.getTime() ? (
              <p
                className="fw-bold m-0"
                style={{ color: "red", cursor: "pointer" }}
                onClick={handleCancelClick}
              >
                Cancel
              </p>
            ) : formatDateByme(partyDate) === formatDateByme(currentDateTime) &&
              partyDateTime > currentTime ? (
              <p
                className="fw-bold m-0"
                style={{ color: "red", cursor: "pointer" }}
                onClick={handleCancelClick}
              >
                Cancel
              </p>
            ) : (
              <p className="fw-bold m-0 text-success">Completed</p>
            )}
          </div>
        )}
      </div>
      <Modal
        backdrop="static"
        show={ticketModal}
        onHide={handleTicketModal}
        centered
      >
        <Container className=" text-center py-5">
          <h6 className="fw-bold text-uppercase" style={{ color: "red" }}>
            Oh No! Don't cancel the house party Please...
          </h6>
          <b>
            Please write down your concerns and we'll try our best to resolve
            them.
          </b>
          <br></br>
          <br></br>
          <textarea
            name="reasonForCancelation"
            type="text"
            onChange={handleReason}
            placeholder="Reason For Cancellation"
            value={reason}
            rows={4}
          ></textarea>
          {response && <p>{response}</p>}
          <div className="commonPopUpBtn">
            <button
              onClick={handleContinue}
              className="btn btn-reg btn-primary fw-bold btn-padding"
              disabled={isSubmitted}
            >
              Confirm Cancellation
            </button>
            <button
              onClick={handleTicketModal}
              className="btn btn-reg btn-secondary fw-bold btn-padding mx-2"
            >
              Go Back
            </button>
          </div>
        </Container>
      </Modal>
    </div>
  );
}

// function PartyCardWithoutImage(props) {
//   return (
//     <div className={`${styles["card-without-img"]} shadow mb-3`}>
//       <div className={`${styles["card-without-img__content"]}`}>
//         <div className="d-flex justify-content-between p-3">
//           <div>
//             <h5 className="fw-bold">{props.title}</h5>
//             <small className="m-0 text-muted">
//               {moment(props.date).format("MMM DD")} | {props.startTime}-
//               {props.endTime}
//             </small>
//             <br />
//             <small className="m-0 text-muted">
//               {/* USE CITYNAME and LOCALITY */}
//               {props.location && props.location}
//             </small>
//           </div>
//           <div className="d-flex flex-column justify-content-between">
//             <h5 className="fw-bold text-right price">&#8377; {props.amount}</h5>
//             <p className="fw-bold text-primary m-0">
//               {props.review_status === 0 ? <>PENDING</> : <>REJECTED</>}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
