import React, { useState, useEffect } from 'react';

import DashboardNav from './DashboardNav';

import Dashboard from './Dashboard/Dashboard';
import Party from './Party/Party';
import PendingRequests from './PendingRequests/PendingRequests';
import GuestList from './Guestlist/GuestList';

import styles from './Profile.module.scss';

import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router';

export default function Profile() {
	let [page, setPage] = useState('profile');
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const history = useHistory();

	useEffect(() => {
		if (!isAuthenticated) {
			return <Redirect push to="/" />;
		}
	}, [isAuthenticated, history]);

	return (
		<>
			<div className="" style={{ padding: '5rem 0 19.5rem 0' }}>
				<div className="">
					<div className="container">
					{/* <DashboardNav page={page} setPage={setPage} /> */}
					{/* PAGE SWITCH ACCORDING TO PAGE STATE */}
					{(() => {
						switch (page) {
							case 'profile':
								return <Dashboard />;
							case 'party':
								return <Party />;
							case 'pending requests':
								return <PendingRequests />;
							case 'guest list':
								return <GuestList />;
							default:
								return <Dashboard />;
						}
					})()}
					</div>
				</div>
			</div>
		</>
	);
}

export function Card({ children, className }) {
	return (
		<div className={`shadow bg-white rounded-lg ${className}`}>{children}</div>
	);
}
