import React from 'react';
import { useHistory } from 'react-router-dom';

export default function NotFoundPage(){
    const history = useHistory();

    return(
        <div className="mTop text-center" style={{ height:"50vh" }}>
            <h1 className="display-1">404</h1>
            <h3 className="text-muted mb-5">Page not found</h3>
            <button className="btn btn-sm btn-primary" onClick={()=>history.goBack()}>Go back</button>
        </div>
    );
}