import React, { useState } from 'react';
import Img from 'react-cool-img';

import { Loader } from '../../../../helpers';

import { v4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

import { useQuery } from 'react-query';
import {
	fetchUpcomingPartiesAsHost,
	fetchGuestlist,
	URL,
} from '../../../../../api/requests';

import { toast, ToastContainer } from 'react-toastify';

import moment from 'moment';

import PartyImg from '../../../../assets/img/attend a party.png';
import DP from '../../../../assets/img/dp.jpg';
import PlaceholderImg from '../../../../../assets/house_party_placeholder.jpg';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function PartyBookingRequestReceived(props) {
	//let [approved, setApproved] = useState(false);

	const {
		data: parties,
		isLoading: isPartiesLoading,
		isPartiesError,
	} = useQuery(
		['FETCH_PARTIES_AS_HOST', props.token],
		fetchUpcomingPartiesAsHost,
		{
			retry: 1,
			refetchOnWindowFocus: true,
		}
	);

	if (isPartiesLoading) {
		return (
			<div className="loading-overlay">
				<Loader />
			</div>
		);
	}

	return (
		<div>
			<p className="text-uppercase text-primary">Booking Requests Received</p>
			{isPartiesError && <p>There was an error.</p>}
			{parties && parties.data !== null && parties.data.length === 0 && (
				<p>No Bookings received</p>
			)}
			{parties &&
				parties.data.map(party => {
					return (
						<React.Fragment key={v4()}>
							<EventCard {...party} />
							<h6 className="my-4 fw-bold">Guestlist</h6>
							<GuestCard token={props.token} event_id={party.event_id} />
							<hr />
						</React.Fragment>
					);
				})}
		</div>
	);
}

function GuestCard(props) {
	let [approval, setApproval] = useState(false);
	let [rejection, setRejection] = useState(false);
	const { data: guestlist, isLoading: isGuestlistLoading } = useQuery(
		['FETCH_GUESTLIST', props.event_id, 0, props.token],
		fetchGuestlist,
		{
			retry: 1,
			refetchOnWindowFocus: true,
			refetchInterval: 1500,
		}
	);

	const notifyApprove = () => {
		setApproval(true);
		toast.success('Guest approved!');
	};
	const notifyReject = () => {
		setRejection(true);
		toast.error('Guest rejected!');
	};

	const approve = async (request_id, token) => {
		await axios
			.post(
				`${URL}v1/event/attend/approval`,
				{
					request_id: request_id,
					status: 1,
					reason: '',
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(response => {
				notifyApprove();
			})
			.catch(errors => {
				console.error(errors);
			});
	};

	const reject = async (request_id, token) => {
		await axios
			.post(
				`${URL}v1/event/attend/approval`,
				{
					request_id: request_id,
					status: 2,
					reason: '',
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(response => {
				notifyReject();
			})
			.catch(errors => {
				console.error(errors);
			});
	};
	return (
		<>
			{!isGuestlistLoading && guestlist.data.length === 0 && (
				<p>No requests received yet.</p>
			)}
			{!isGuestlistLoading &&
				guestlist.data.map(guest => {
					return (
						<div className="d-flex my-3" key={v4()}>
							<div className="d-flex align-items-center w-75">
								<Img
									src={guest.guest_image}
									error={DP}
									className="rounded-circle img-fluid"
									height="30"
									width="30"
								/>
								<p className="m-0 fw-bold ml-3">{guest.guest_name}</p>
							</div>
							<div className="d-flex align-items-center justify-content-between w-25">
								{/* <button onClick={()=>{approve(guest.request_id, props.token)}}> */}
								<FontAwesomeIcon
									icon={faCheckCircle}
									size="2x"
									className="text-approve mr-2 make-pointer"
									onClick={() => {
										approve(guest.request_id, props.token);
									}}
								></FontAwesomeIcon>
								{/* </button> */}
								{/* <button onClick={()=>{reject(guest.request_id, props.token)}}> */}
								<FontAwesomeIcon
									icon={faTimesCircle}
									size="2x"
									className="text-reject ml-2 make-pointer"
									onClick={() => {
										reject(guest.request_id, props.token);
									}}
								></FontAwesomeIcon>
								{/* </button> */}
							</div>
							{approval && (
								<ToastContainer
									position="bottom-center"
									autoClose={2000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick={false}
									rtl={false}
									pauseOnFocusLoss={false}
									draggable={false}
									pauseOnHover
								/>
							)}
							{rejection && (
								<ToastContainer
									position="bottom-center"
									autoClose={2000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick={false}
									rtl={false}
									pauseOnFocusLoss={false}
									draggable={false}
									pauseOnHover
								/>
							)}
						</div>
					);
				})}
		</>
	);
}

export function EventCard(props) {
	return (
		<Link to={`/parties/${props.event_id}`} className="link-unstyled">
			<div className="d-flex justify-content-start align-items-center my-3">
				<div className="col-md-6 d-flex justify-content-center">
					<Img
						src={props.image ? props.image : PlaceholderImg}
						className="img-fluid"
						style={{ objectFit: 'contain', width: 'auto', height: '75px' }}
					/>
				</div>
				<div className="col-md-6 ml-3">
					<h6 className="fw-bold">{props.heading}</h6>
					<small className="text-muted d-block">
						{moment(props.date).format('MMM DD')} | {props.time_from} -
						{props.time_to}
					</small>
					<small className="text-muted d-block">
						{/* USE CITYNAME and LOCALITY */}
						{props.location && props.location.locality},{' '}
						{props.location && props.location.location}
					</small>
				</div>
			</div>
		</Link>
	);
}
