import React, { useEffect } from 'react';

// import { BrowserRouter } from 'react-router-dom';
import { Header, Footer } from './components/pages/layout';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

function App() {

	useEffect(() => {

		const tagManagerArgs = {
			gtmId: process.env.REACT_APP_GA_TRACKING_ID
		};

		ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_TRACKING_ID }]);
		TagManager.initialize(tagManagerArgs);

	}, [])


	return (
		<>
			<Header />
			<Footer />
		</>
	);
}

export default App;
