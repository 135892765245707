import React from "react";
import { Container, Card, Accordion } from "react-bootstrap";

export default function Faq() {
  return (
    <Container style={{marginTop: "5.5rem"}}>
      <h1 className="text-center">FAQ</h1>
      <div>
        <h2 className="my-5">House Parties - Hosting</h2>
        <Container>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h5>How do I host a house party?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Hosting is easy and free. Fill in the hosting form by clicking
                  ‘Host a House Party’. Your hosting request will be reviewed by
                  our team and if it meets our community guidelines, your party
                  will be approved and will be live on the platform.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h5>Where do I host the house party?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  You can host the party at your place. Ideally in common areas
                  like living rooms, balconies, terraces, backyard etc. You can
                  also book a space of your choice from the featured spaces.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h5>
                  How do I know that a stranger attending the house party is not
                  a creep?
                </h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Every user is verified with multiple layers of verifications.
                  Users have to sign up with their personal mobile numbers and
                  social media accounts. Further, they have to upload their
                  government ids. Additionally you can also call them up as a
                  courtesy with our secured calling systems, to know more about
                  your guests and to see if they match your interest.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <h5>Who gets to choose the guests?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  You can host the party at your place. Ideally in common areas
                  like living rooms, balconies, terraces, backyard etc. You can
                  also book a space of your choice from the featured spaces.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <h5>How much can I earn from a house party?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Every host has the chance to earn upto 75% of the booking
                  amount. The more you host the more you can earn.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <h5>How and when will I receive the payment?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  The amount paid by the guests in accumulated with our payment
                  gateway’s account. After a successful completion of the party,
                  the amount is transferred to your bank account after the
                  deduction of commissions within a week.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                <h5>
                  Are there any particular requirements for hosting a house
                  party?
                </h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Your space should be clean and presentable. Make sure you
                  offer plenty of water and some light nibbles. This would be a
                  nice gesture. Some warm yellow lights sets up the mood and
                  does the trick. A good experience will get you regular guests
                  and good reviews.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                <h5>How do I decide the pricing?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Simply think what price would you pay for your offerings.
                  Guests attend these house parties because they are casual and
                  reasonable. Impractical prices will not fetch bookings.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                <h5>How many guests should I accept?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Make sure you don’t accept guests more than what the place
                  could accommodate. We are sure you wouldn’t like to go to a
                  crammed up place either. A congested place would ruin the
                  experience.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
      <div>
        <h2 className="my-5">House Parties - Attending</h2>
        <Container>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h5>How do I attend a house party?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  You can browse through the listed parties and choose the theme
                  you like. Click on the ‘Request to Book’ button inside the
                  party profile page. Process the payment to send your request
                  to the host. Please ensure that you are a verified user to
                  increase your chances to be accepted.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h5>
                  Is it safe to attend the house parties hosted on your
                  platform?
                </h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Every user is verified with multiple layers of verifications.
                  Users have to sign up with their personal mobile numbers and
                  social media accounts. Further, they have to upload their
                  government identities too. Additionally, the host’s venue is
                  also reviewed before any party is approved.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h5>
                  Why should I pay first to send my request to attend a house
                  party?
                </h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Every house party has limited spots available and the host has
                  to prepare accordingly. Your payment works as a confirmation.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <h5>What if my request does not get accepted?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  If your request does not get accepted, a full refund is
                  initiated immediately and the amount is sent back to the
                  original source of the payment. It could take 3-7 working days
                  for the amount to reflect in your account, depending on the
                  bank you transact with.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <h5>Is food and beverage included in the contribution?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  It really depends on what the host is offering. Sometimes, it
                  is, if the house party is sponsored by a food or beverage
                  partner, please check the amenities section inside the party
                  profile page to know more about the offerings. Generally you
                  can BYOB and you can order or get your own food too. It is a
                  good idea to contribute for food at the party with everyone.
                  It is always a special occasion when you share food with
                  people and eat together. That’s where the bonds are made.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <h5>What am I paying for?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  You are paying for the experience, the space and the time and
                  effort put in by the host.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                <h5>On what basis is my request approved?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  As long as you are a verified user and not flagged by any
                  previous host or a guest, half of the job is done. Most of the
                  times, if your request does not go through, it could be
                  because the host could not accommodate you owing to limited
                  spots available. A host cannot disapprove your request based
                  of sex, caste, creed or religion as per the hosting agreement
                  agreed by the host.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                <h5>Can I attend the party alone?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Of course you can. Our experience says that attending alone is
                  more fun. You will remember us for our words and will be
                  surprised that you aren’t alone by the end of the party.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                <h5>I am an introvert, will I be left out?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  What if we tell you that most of the people who attend these
                  house parties are introverts? This platform is created
                  especially for introverts like us. Keeping that that in mind,
                  the party is curated in such a way that every guest is
                  involved and not left out. All you have to do is, stop
                  thinking too much and take that step ahead.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="9">
                <h5>What should I expect at these house parties?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Every house party is themed so that you can meet like-minded
                  strangers. Most of these house parties are designed to have a
                  casual vibe and great conversations. At the end of the day its
                  the conversations that make these house parties special and
                  memorable. Sometimes if there is an artist performing you can
                  sing your favorite songs and can shake a leg too.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="10">
                <h5>
                  Is it safe to share my details and documents with you’ll?
                </h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body style={{'lineHeight':'1.5'}}>
                  Sensitive details like your phone number, email address,
                  social media profile links and your government identity
                  documents are handled with utmost caution and are safe with
                  us. It is essential to share these details as a part of
                  verifications to enable us to create a safer community. The
                  details that you share as a part of your profile on playace.co
                  is at your discretion and is recommended so that the other
                  users get an overview of you as an individual.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
    </Container>
  );
}
