import React from "react";

import { GuestListCard } from "../GuestList";
// import SpaceImg from "../../../../assets/img/book a spcae.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
// import Img from "react-cool-img";

export default function SpaceGuestList(props) {
  // let guests = false;
  return (
    <GuestListCard nametag="Spaces" classes="space pt-5 pb-4 px-4">
      <>
        <p className="mt-5 text-secondary text-center">No spaces listed yet.</p>
        <Link
          to="/list-space"
          className="mx-auto btn btn-outline-secondary text-right fw-bold rounded-lg"
        >
          Start listing now
        </Link>
      </>
      {/* <SpaceCard />
      <h6 className="text-uppercase mt-4 mb-3 fw-bold">Upcoming Bookings</h6>
      <div>
        <Guest />
        <Guest />
        <Guest />
      </div>
      <hr />
      <SpaceCard />
      <h6 className="text-uppercase mt-4 mb-3 fw-bold">Upcoming Bookings</h6>
      <div>
        {guests ? (
          <>
            <Guest />
            <Guest />
            <Guest />
          </>
        ) : (
          <p className="text-secondary">No bookings yet</p>
        )}
      </div> */}
    </GuestListCard>
  );
}

// function SpaceCard(props) {
//   return (
//     <div className="d-flex mt-5 align-items-center">
//       <Img src={SpaceImg} className="img-fluid d-block" width="150" />
//       <div className="ml-3">
//         <h5 className="fw-bold">A hidden gem amidst the city</h5>
//         <div className="d-flex justify-content-between">
//           <small className="text-muted d-block">Khar East</small>
//           <div>
//             <FontAwesomeIcon icon={faUser} size="1x" className="text-muted" />
//             <small className="text-muted ml-2">20</small>
//           </div>
//         </div>
//         <div className="d-flex justify-content-between">
//           <p className="fw-bold m-0">&#8377; 1200</p>
//           <small className="text-muted d-block">Living Room</small>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Guest(props) {
//   return (
//     <div className="d-flex justify-content-between my-2">
//       <p className="m-0 text-secondary">Guest Name</p>
//       <small className="text-muted">
//         Wed, 26 Sept 2020 | 10:00pm to 4:00pm
//       </small>
//     </div>
//   );
// }
