import React, { useState, useEffect } from "react";
import CarouselSlider from "react-carousel-slider";
import { useQuery } from "react-query";
import { Container, Row, Col } from "react-bootstrap";

import { fetchBannerAds } from "../../../api/requests";

function EventHostImages(props) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    disableScrolling();
  };

  const handleClosePopup = () => {
    setSelectedImageIndex(null);
    enableScrolling();
  };

  const disableScrolling = () => {
    document.body.style.overflow = "hidden";
    document.addEventListener("click", handleOutsideClick);
  };

  const enableScrolling = () => {
    document.body.style.overflow = "auto";
    document.removeEventListener("click", handleOutsideClick);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".popup-content")) {
      handleClosePopup();
    }
  };

  useEffect(() => {
    return () => {
      enableScrolling(); // Ensure scrolling is enabled when the component unmounts
    };
  }, []);

  let manner = {
    autoSliding: { interval: "5s" },
    duration: "0.5s",
  };

  let sliderBoxStyle = {
    height: "300px",
    width: "100%",
    background: "transparent",
    border: "none",
  };

  let itemsStyle = {
    height: "100%",
    width: "auto",
    background: "transparent",
    border: "none",
    margin: "0px 8px",
    borderRadius: "10px",
  };

  let textBoxStyle = {
    display: "none",
  };

  let buttonSetting = {
    style: {
      left: {
        display: "none",
      },
      right: {
        display: "none",
      },
    },
  };

  let dotsSetting = {
    placeOn: "bottom",
    style: {
      dotSize: "5px",
      currDotColor: "#FF1844",
      marginTop: "2px",
    },
  };

  let banners =
    props.images &&
    props.images.map(function (item, index) {
      return (
        <img
          key={index}
          src={item}
          alt="Playace parties"
          width="522"
          height="294"
          onClick={() => handleImageClick(index)}
          style={{ cursor: "pointer" }}
        />
      );
    });

  let webBanners = banners;

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="slider" style={{ position: "relative" }}>
            {webBanners && (
              <>
                <CarouselSlider
                  slideCpnts={webBanners}
                  manner={manner}
                  sliderBoxStyle={sliderBoxStyle}
                  dotsSetting={dotsSetting}
                  buttonSetting={buttonSetting}
                  itemsStyle={itemsStyle}
                  textBoxStyle={textBoxStyle}
                />
                {selectedImageIndex !== null && (
                  <div
                    className="popup"
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      className="popup-content"
                      style={{
                        position: "relative",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                        maxWidth: "100%",
                        padding: "70px",
                      }}
                    >
                      <img
                        src={props.images[selectedImageIndex]}
                        alt="Playace parties"
                        style={{ width: "100%", height: "300px" }}
                      />
                      <button
                        onClick={handleClosePopup}
                        style={{
                          marginTop: "10px",
                          padding: "5px 10px",
                          backgroundColor: "#FF1844",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EventHostImages;
