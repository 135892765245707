import React from "react";

export default function HouseParties() {
  return (
    <div>
      <h4 align="center" className=" maintopTit">
        Terms and Conditions
      </h4>
      <p>
        Welcome to www.playace.co., an online guide for house parties in the
        city of Mumbai. (hereinafter referred to as “Site”). By using or
        accessing this Site or its mobile application operated by us on which
        these terms and conditions are posted via a link or otherwise, you
        acknowledge and agree that you are subject to the following terms and
        conditions (Terms), as well as our privacy policy, which shall also
        govern your use of the Site. If you do not accept any of these Terms,
        then you may not use the services. In these Terms, "we", "us", "our" or
        "playace.co" refers to www.playace.co., and "you" or "your" refers to
        the customer visiting the Site, user of the services and/or contributing
        content on this Site.
      </p>
      <p>
        The user of this Site is requested to read through all the Terms
        carefully, as these Terms contain an arbitration clause that requires
        disputes to be arbitrated on an individual basis. The Terms constitute a
        legally binding agreement between you and playace.co; and it shall
        affect how disputes between you and us are resolved. You are not
        authorized to use this Site unless you are at least 18 years of age and
        able to enter into legally binding contracts. We do not knowingly
        collect the information of anyone under the age of 18.
      </p>
      <ol>
        <li>
          <span class="fw-bold">SCOPE OF WWW.PLAYACE.CO.</span>
        </li>
        <p>
          Playace.co. is an online director of ongoing and upcoming house
          parties in Mumbai. The Site is a venue and not a party organizer or
          Host of any event. We urge all users to be responsible about their use
          of this Site and any transaction entered into as a result of either
          listing an event or attending an event. We also may offer online
          booking for the event or other tools or services to allow users to
          communicate with each other and enter into agreement or other
          transactions.{" "}
        </p>
        <p>
          We are not a party to any other agreement between users. This is true
          even if the Site allows you to book your pass for a house party, or
          provides other ancillary products or services, as the Site may
          facilitate booking a pass or other services, but we are not an
          organizer of any house party or other agreement between users. As a
          result, any part of an actual or potential transaction between the
          party host and its attendant, including the quality, condition, safety
          or legality of the event advertised, the truth or accuracy of the
          listings (including the content thereof or any review relating to any
          party organizer or property), the ability of party organizer to host a
          house party or the ability of party attendant to enter into an
          agreement are solely the responsibility of each user.
        </p>

        <li>
          <span class="fw-bold">MODIFICATION</span>
        </li>
        <p>
          We may change or modify this agreement in the future; we shall post a
          revised version of this agreement here, which shall automatically
          replace the terms of this Agreement. Your continued access or use of
          this Website and its services after such change signifies your
          acceptance of the updated or modified Agreement. If you disagree with
          the terms of this Agreement or any revised version of this Agreement,
          then discontinue using services of this Site.
        </p>

        <li>
          <span class="fw-bold">ACCOUNT REGISTRATION</span>
          <ol>
            <li>Requirements:</li>
            <p>
              To be a registered user of this Site, you must agree to accept the
              terms and conditions of this Agreement. You may only use the
              Services if you are able to form legally binding contracts, are
              over the age of 18. If you do not qualify, please do not use or
              try to use the services. If you are under the age of 18, you may
              only use this site in conjunction with and under the supervision
              of your parents or guardians.
            </p>
            <li>Registration:</li>
            <p>
              We will not accept any request from you to list a house party in
              your property before you have registered with us. To register, you
              must provide your legal and current name, address, phone number
              and an active email address.
            </p>
            <li>Email address and password:</li>
            <p>
              You will need an email address and password to access the Site and
              book the Services. You are solely responsible for maintaining the
              security of your email address and password and for all activity
              that occurs under your email address and password. You should not
              disclose your password to any other person.
            </p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">LIMITATION TO USE THE SITE</span>
        </li>
        <p>
          Members/Users are granted a limited, revocable non-exclusive access to
          the Site and the content, review and services provided on the Site
          solely for the purpose of advertising a house party, searching for an
          ongoing or upcoming house party, purchasing or booking passes, offered
          on our Site, participating in an interactive area hosted on the Site
          or for any other purpose clearly stated on a Site, all in accordance
          with the Terms. Any use of the Site that is not for one of these
          purposes or otherwise in accordance with the Terms or as otherwise
          authorized by us in writing is expressly prohibited.
        </p>

        <li>
          <span class="fw-bold">UNAUTHORISED USES OF THE SITE</span>
          <p>
            The content and information on this Site (including, but not limited
            to, messages, data, information, text, music, sound, photos,
            graphics, video, maps, icons, software, code or other material), as
            well as the infrastructure used to provide such content and
            information, is proprietary to us. You agree not to otherwise
            modify, copy, distribute, transmit, display, perform, reproduce,
            publish, license, create derivative works from, transfer, or sell or
            re-sell any information, software, products, or services obtained
            from or through this Website. Unauthorized uses of the Site also
            include, without limitation, those listed below. You agree not to do
            any of the following, unless otherwise previously and specifically
            agreed to by us:
          </p>
          <ol class="roman">
            <li>
              deep-link to any portion of this Website for any purpose without
              our express written permission;
            </li>
            <li>
              "frame", "mirror" or otherwise incorporate any part of this
              Website into any other website without our prior written
              authorization.
            </li>
            <li>
              attempt to modify, translate, adapt, edit, decompile, disassemble,
              or reverse engineer any software programs used by us in connection
              with the Website.
            </li>
            <p></p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">
            LISTING OF HOUSE-PARTY BY EVENT ORGANIZERS
          </span>
          <ol>
            <li>Listing Process:</li>
            <p>
              A registered user who wants to list a house party may list the
              event with us on playace.co. As part of the listing process, the
              party organizer shall assign a rate of the party pass and shall
              also provide information such as the event description, check-in
              date, check-out date, check-in time, check-out time, accessible
              areas in the house, number of bathrooms, number of people
              attending the party, and other amenities.
            </p>
            <li>Organizers responsibility:</li>
            <p>
              Once the party organizer confirms reservation of a party
              attendant, the organizer is responsible for fulfilling the
              reservation with the exact specifications that the organizer
              listed. Playace.co. is not responsible for any errors made by the
              organizer in the listing of a house party and is not liable for
              any such errors.
            </p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">
            FINDING A HOUSE PARTY AND SECURING RESERVATIONS AS A PARTY ATTENDANT
          </span>
          <ol>
            <li>Making Reservations:</li>
            <p>
              To make a reservation, a party attendant must first browse the
              listings on playace.co. for the upcoming house parties that match
              what the party attendant is seeking. When the party attendant
              finds desirable event, the attendant places a “booking request” to
              reserve the place in the said house party. All booking requests
              are final and, once placed, cannot be cancelled or retracted.
              Placing a booking request does not complete the reservation. Party
              Organizers have 72 hours to receive notification of a reservation
              and confirm the booking. The reservation is complete and takes
              effect only after the party organizer has confirmed the
              reservation and the attendant’s credit or debit card has been
              charged.
            </p>
            <li>House-Party Listing Information:</li>
            <p>
              Event details, check-in date, check-out date, check-in time,
              check-out time, number of guests, accessible areas in the party,
              number of guests property can accommodate, menu and other
              amenities available may change. It is up to you, the party
              attendant, to verify the most recent changes.
            </p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">SERVICE FEES</span>
        </li>
        <p>
          Playace.co. shall charge service fees to the party organizer and the
          party attendant, in consideration for the use of the playace.co.
          platform. Service fees shall be inclusive of tax, and the same shall
          be displayed to the organizer and attendant prior to publishing or
          booking a house party. We reserve right to amend any change in the
          service fees, and the same shall be notified to the members of our
          Site. Service fees charged by Playace.co. are non-refundable.
        </p>

        <li>
          <span class="fw-bold">THINGS WE DO NOT ALLOW</span>
          <p>You agree not to use the Playace.co. website to:</p>
          <ol class="roman">
            <li>Make offline reservation</li>
            <li>Use abusive language</li>
            <li>Post adult material</li>
            <li>Conduct illegal activity</li>
            <li>Double posting of a house party</li>
            <li>Contact other Playace users</li>
            <li>Invite contact with other Playace users,</li>
            <li>
              Initiate or engage in transactions outside of Playace. Playace.co.
              is not responsible for any damages or lost profits that result
              from Playace users directly contacting other Playace users or for
              any damages or lost profits resulting from transactions conducted
              outside of Playace, including transactions that originate at
              Playace and are taken offline.
            </li>
            <p></p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">REVIEWS AND COMMENTS</span>
        </li>
        <p>
          Playace.co. appreciates hearing from you. Please be aware that you
          grant us a non-exclusive, royalty free, perpetual, transferable and
          irrevocable right to use, reproduce or modify any review or comment
          posted on this Site, including party review, photographs, videos,
          comments, suggestions, or ideas. You acknowledge and agree that
          Submissions are non-confidential and non-proprietary. This Site may
          contain discussion forums, review services or other forums in which
          you or third parties may post reviews of any house party experience.
          You are solely responsible for your use of such Interactive Areas and
          use them at your own risk.
        </p>

        <li>
          <span class="fw-bold">TERMINATION</span>
          <p>
            We may take any action that we deem fit in our sole discretion
            (including without limitation issuing a warning, suspending or
            terminating service, denying access, removing a listing or
            recommending you edit a listing) if:
          </p>
          <ol class="roman">
            <li>The user breaches this Agreement</li>
            <li>
              We are unable to verify or authenticate any information you
              provide;{" "}
            </li>
            <li>
              We believe your conduct may cause legal liability for you, for
              other users, or for us.{" "}
            </li>
            <p></p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">DISCLOSURE OF INFORMATION</span>
        </li>
        <p>
          You agree that Playace.co. may report any activity that it suspects
          violates any law or regulation to appropriate law enforcement
          officials, regulators, or other relevant third parties. Playace.co.
          will cooperate to ensure that violators are prosecuted to the fullest
          extent of the law.
        </p>

        <li>
          <span class="fw-bold">LAWS AND REGULATIONS</span>
        </li>
        <p>
          You warrant that you will comply with all applicable local, state,
          federal and international laws, statutes and regulations regarding use
          of the Site. The provisions of these Terms shall be governed by and
          construed in accordance with Indian law and the courts at Mumbai shall
          have exclusive jurisdiction in respect of any matter arising out of
          this Agreement.
        </p>
        <p>
          Any dispute, controversy or claims arising out of or relating to this
          agreement or the breach, termination or invalidity thereof, shall be
          settled by arbitration in accordance with the provisions of the
          Arbitration and Conciliation Act, 1996. The place of Arbitration shall
          be Mumbai and any award whether interim or final, shall be made, and
          shall be deemed for all purposes between the parties to be made, in
          Mumbai.
        </p>

        <li>
          <span class="fw-bold">INDEMNITY</span>
        </li>
        <p>
          You agree to indemnify and hold Playace.co. and (if applicable) any
          parent, subsidiaries, affiliates, officers, directors, attorneys,
          agents and employees, harmless against any claim or demand and all
          liabilities, costs and expenses (including reasonable attorneys’ fees)
          incurred by Playace.co. that involves, relates to or concerns any of
          your actions or omissions on this Site, including but not limited to
          your breach of these Terms, or your violation of any law or the rights
          of a third party.
        </p>

        <li>
          <span class="fw-bold">CAPTIONS</span>
        </li>
        <p>
          The Captions at the beginning of each paragraph of these Terms is for
          reference purposes only and in no way defines, limits, construes or
          describes the scope or extent of such paragraph.
        </p>

        <li>
          <span class="fw-bold">INTELLECTUAL PROPERTY RIGHTS</span>
          <ol>
            <li>
              You acknowledge and agree that, our patents, trademarks, trade
              names, service marks, copyrights and other intellectual property
              (collectively, “Intellectual Property”) are and shall remain our
              sole property, and nothing in these Terms shall confer in you any
              right of Ownership or license rights in our Intellectual Property.
              In addition, you shall not now or in the future contest the
              validity of our Intellectual Property.
            </li>
            <li>
              The software and the Site, including without limitation all text,
              graphics, logos, buttons, icons, images, audio clips, and computer
              programs, are the property of Playace.co. or its suppliers, and
              are protected by Indian and international copyright, trademark and
              other laws. Any unauthorized reproduction, modification,
              distribution, transmission, republication, display, or performance
              of the software or the content on the Site is strictly prohibited.
            </li>
            <li>
              We respect the intellectual property rights of others, and hence
              we do not permit, condone or tolerate the posting of any content
              on the Site that infringes any person's copyright. We shall
              terminate, in appropriate circumstances, a member who is the
              source of repeat infringement of copyright. Should you become
              aware of or suspect any copyright infringement on this Site,
              please notify us about the IP Infringement, which are incorporated
              by reference into these Terms.
            </li>
            <p></p>
          </ol>
        </li>

        <li>
          <span class="fw-bold">NOTICES</span>
        </li>
        <p>
          Except as expressly stated otherwise, all notices to Playace.co. shall
          be sent to the email addresses provided on the Site. Except as
          expressly stated otherwise, all notices to you shall be sent to the
          email address you provided to us during the registration process. Such
          notice shall be deemed given one business day after the email is sent.
        </p>

        <li>
          <span class="fw-bold">SEVERABILITY</span>
        </li>
        <p>
          The invalidity or unenforceability of any provisions of these Terms in
          any jurisdiction shall not affect the validity, legality or
          enforceability of the remainder of these Terms in such jurisdiction or
          the validity, legality or enforceability of these Terms, including any
          such provision, in any other jurisdiction, it being intended that all
          rights and obligations of the Parties hereunder shall be enforceable
          to the fullest extent permitted by law.
        </p>

        <li>
          <span class="fw-bold">MISCELLANEOUS</span>
        </li>
        <p>
          These Terms constitute the entire agreement between the parties
          pertaining to the subject matter hereof and supersedes all prior
          agreements and understandings between the parties hereto with respect
          to the subject matter hereof, whether written or oral. No amendment,
          modification or supplement of any provision of these Terms will be
          valid or effective unless made in accordance with the express terms of
          the Terms. If any provision of these Terms is held to be invalid or
          unenforceable under any circumstances, its application in any other
          circumstances and the remaining provisions of the Terms shall not be
          affected.
        </p>
      </ol>
    </div>
  );
}
