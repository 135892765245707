import {
    UPDATE_GUEST_COUNT,
    UPDATE_PROMO_CODE, UPDATE_QUOTATION_DATA
} from "../actions/types";


export const updateGuestCount = (data) => ({
    type: UPDATE_GUEST_COUNT,
    payload: data,
});

export const updatePromoCode = (data) => ({
    type: UPDATE_PROMO_CODE,
    payload: data,
});

export const updateQuotationDAta = (data) => ({
    type: UPDATE_QUOTATION_DATA,
    payload: data,
});




