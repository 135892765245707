import {
  AUTHENTICATE_USER_START,
  AUTHENTICATE_USER_SUCCESS,
  LOAD_CURRENT_USER,
  AUTHENTICATE_USER_FAILURE,
  UPDATE_SELECTED_CITY,
  UPDATE_IS_HOST,
} from "../actions/types";

const initialState = {
  data: {},
  loading: false,
  isAuthenticated: false,
  bookClicked: false,
  errors: [],
  selectedCity: {
    id: 0,
    city: "All Cities"
  },
  isHost: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_START:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        isAuthenticated: true,
        errors: [],
      };
    case AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case LOAD_CURRENT_USER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        isAuthenticated: true,
        errors: [],
      };
    case "LOGOUT_USER":
      return {
        ...state,
        data: action.payload,
        isAuthenticated: false,
        loading: false,
      };
    case "BOOK_CLICKED_TRUE":
      return {
        ...state,
        bookClicked: true,
      };
    case "BOOK_CLICKED_FALSE":
      return {
        ...state,
        bookClicked: false,
      };
    case UPDATE_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case UPDATE_IS_HOST:
      return {
        ...state,
        isHost: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
