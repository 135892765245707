import {
    GET_PROPERTY_LIST_START,
    GET_PROPERTY_SUCCESS,
    GET_PROPERTY_FAILURE,
} from '../actions/types';

const initialState = {
    "data": [],
    "loading": true,
    "errors": []
}

export default function(state= initialState, action){
    switch (action.type){
        case GET_PROPERTY_LIST_START:
            return {
                ...state,
                "loading": true
            }

        case GET_PROPERTY_SUCCESS:
            return {
                ...state,
                "loading": false,
                "data":action.payload
            }

        case GET_PROPERTY_FAILURE:
            return {
                ...state,
                "loading":false,
                "errors":action.payload
            }
            
        default: 
            return {...state}
    }
}