import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EventCard } from '../../modules/content';
import { fetchProperties } from '../../../api/requests';
import { RenderUserObj } from '../../helpers';

import '../../assets/index.scss';

function Listing(props) {
	// let [spaces, setSpaces] = useState([]);

	// let spacesObj = spaces.splice(0, 4);

	// useEffect(() => {
	//   getAllProperties(setSpaces);
	// }, []);
	let [token, setToken] = useState(null);

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));

	useEffect(() => {
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [isAuthenticated, userObj]);

	const { data, isLoading } = useQuery(
		['propertyListing', token],
		fetchProperties,
		{
			retry: 1,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	let spacesObj = data && data.splice(0, 4);

	return (
		<section className="mTop">
			{props.title ? (
				<>
					{props.title ? (
						<h2>{props.title}</h2>
					) : (
						<h2>Discover unique spaces</h2>
					)}
					<p className="mb-5 text-muted">
						Book the perfect playace for your social gatherings
					</p>
					{/* <div className="grid-row">
						{spacesObj &&
							!isLoading &&
							spacesObj.map(card => (
								<EventCard key={card.id} type="property" {...card} />
							))}
					</div> */}
					{props.button ? (
						<div className="text-center my-4">
							<Link
								to="/spaces"
								className="btn btn-reg btn-outline-secondary fw-bold btn-padding"
							>
								Explore more spaces
							</Link>
						</div>
					) : null}
				</>
			) : (
				<>
					<Container>
						{props.title ? (
							<h2>{props.title}</h2>
						) : (
							<h2>Discover unique spaces</h2>
						)}
						<p className="mb-5 text-muted">
							Book the perfect playace for your social gatherings
						</p>
						{/* <div className="grid-row">
							{spacesObj &&
								!isLoading &&
								spacesObj.map(card => (
									<EventCard key={card.id} type="property" {...card} />
								))}
						</div> */}
						{props.button ? (
							<div className="text-center my-4">
								<Link
									to="/spaces"
									className="btn btn-reg btn-outline-secondary fw-bold btn-padding"
								>
									Explore more spaces
								</Link>
							</div>
						) : null}
					</Container>
				</>
			)}
		</section>
	);
}

export default Listing;
