import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import verified1 from "../../../assets/Frame 663 (1).png";
import verified2 from "../../../assets/Frame 663 (2).png";
import verified3 from "../../../assets/Frame 663 (3).png";
import mobverified1 from "../../../assets/Frame 698 1.png";
import mobverified2 from "../../../assets/Frame 699 1.png";
import mobverified3 from "../../../assets/Frame 700 1.png";
import { Default, Mobile } from "../../helpers/Breakpoints";

export default function VerifiedInfo() {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [verified3, verified1, verified2];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="wholegbg_black verifiedAnimate">
      <Container>
        <Mobile>
          <div className="verifyGrid">
            <div className="rightAnimate">
              <div className="animateinner">
                <div className="singleInfo">
                  <div className="imgContVerify">
                    <img src={mobverified1} alt="" />
                  </div>
                  <div className="infoText">
                    <h4>Verified Profiles</h4>
                    <p>
                      All guest profiles undergo strict verification, including
                      OTP, ID upload, selfie match, and tele verification.
                    </p>
                  </div>
                </div>
                <div className="singleInfo">
                  <div className="imgContVerify">
                    <img src={mobverified2} alt="" />
                  </div>
                  <div className="infoText">
                    <h4>Balanced and Fun Parties</h4>
                    <p>
                      Each party requires at least 7 attendees and a 60:40
                      gender ratio to ensure a lively and inclusive atmosphere.
                      If these conditions aren't met, guests are notified 2
                      hours before the event.
                    </p>
                  </div>
                </div>
                <div className="singleInfo">
                  <div className="imgContVerify">
                    <img src={mobverified3} alt="" />
                  </div>
                  <div className="infoText">
                    <h4>Real Reviews for Great Experiences</h4>
                    <p>
                      Rate hosts and guests to help others. Share feedback on
                      the vibe and entertainment. Read genuine reviews to know
                      what to expect and enjoy an amazing house party.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Mobile>
        <Default>
          <div className="verifyGrid">
            <div className="leftPicCont">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index}`}
                  className={`leftImage ${
                    activeIndex === index ? "active" : ""
                  }`}
                />
              ))}
            </div>

            <div className="rightAnimate">
              <div className="animateinner">
                {[
                  "Verified Profiles",
                  "Balanced and Fun Parties",
                  "Real Reviews for Great Experiences",
                ].map((title, index) => (
                  <div
                    key={index}
                    className={`singleInfo ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <div className="circleIndicator"></div>
                    <div className="infoText">
                      <h4>{title}</h4>
                      <p>
                        {index === 0 &&
                          "All guest profiles undergo strict verification, including OTP, ID upload, selfie match, and tele verification."}
                        {index === 1 &&
                          "Each party requires at least 7 attendees and a 60:40 gender ratio to ensure a lively and inclusive atmosphere. If these conditions aren't met, guests are notified 2 hours before the event."}
                        {index === 2 &&
                          "Rate hosts and guests to help others. Share feedback on the vibe and entertainment. Read genuine reviews to know what to expect and enjoy an amazing house party."}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Default>
      </Container>
    </section>
  );
}
