import React, { useRef, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Default, Login, Signup } from "./index";
import axios from "axios";
import { URL, NEW_URL, verifyUser, sendOtpWhatsapp } from "../../../api/requests";
import styles from "./Auth.module.scss";
import { Default as Desktop, Mobile } from "../../helpers/Breakpoints";

import loginGraphic from "../../../assets/Rectangle 132.png";
import { useDispatch } from "react-redux";
import { authenticateUserRequest, updateIsHost } from "../../../actions/authActions";
import { debounce, getLoader, showErrorToast, showSuccessToast } from "../../../utils/utils";

//returns a switch statement rendering based on whether otp has been sent
//and whether account exists
export default function Auth(props) {
  let [phone, setPhone] = useState("");
  let [otpsent, setOtpSent] = useState(null);
  let [newUser, setNewUser] = useState(false);
  let [errorShow, setErrorShow] = useState(false);

  let [apiErrorMessage, setApiErrorMessage] = useState("");

  let [otpValue, setOtpValue] = useState("");

  const [loading, setLoading] = useState(false);

  const otpFromModel = useRef(null);

  const dispatch = useDispatch();

  const handleSubmitRequest = debounce((data) => {
    setLoading(true);
    let phone = data.phone;
    setPhone(phone);

    sendOtpWhatsapp({ phone }).then((response) => {
      if (response.status === "success") {
        setOtpSent(true);
        setErrorShow(false);
        setOtpValue(response?.data?.otp)
        showSuccessToast(response?.message ?? "OTP sent successfully");
      }
      else {
        showErrorToast(response?.message ?? "Something went wrong. Please try again later");
      }
      // } else if (response.data.message === "Account is not active.") {
      //   setErrorShow(true);
      // } else {
      //   setOtpSent(false);
      // }
    })
      .catch((errors) => {
        console.log(errors);
        setOtpSent(false);
        showErrorToast("Something went wrong. Please try again later");
      }).finally(() => {
        setLoading(false);
      })
  }, 500);

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const handleVerify = debounce(async (otp) => {
    try {
      setLoading(true);
      let request = {
        phone,
        otp
      }
      otpFromModel.current = otp;
      const response = await verifyUser(request);


      if (response.status === "success") {
        if (response?.isUserExist) {
          dispatch(authenticateUserRequest(response));
          // dispatch(updateIsHost(response?.data?.accountType == "host"));
          props.handleClose();
          props?.handleLogin();
        } else {
          setOtpSent(false);
        }
        showSuccessToast(response?.message ?? "OTP verified successfully");
      } else {
        setApiErrorMessage(response?.message)
        showErrorToast(response?.message ?? "Something went wrong. Please try again later");
      }

    } catch {

    } finally {
      setLoading(false);
    }
  }, 500);

  const onSignup = () => {
    props?.handleClose();
    props?.handleLogin();
  }

  let isOTPSent = otpsent;

  return (
    <React.Fragment>
      {/* <Desktop> */}
      <Modal.Body className="">
        {loading && getLoader()}
        <div className="gridLogSig">
          <div className="relative">
            {/* {!newUser ? (
								<h4 className="text-white text-center mt-5">Login now!</h4>
							) : (
								<h4 className="text-white text-center mt-5">Signup now!</h4>
							)} */}
            <img
              className="logSinBgImg"
              src={loginGraphic}
              alt="login playace"
              width="160px"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center paddlefr"
          >
            <div
              className={` text-white ${styles.closebtn}`}
              onClick={props.handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-x"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </div>
            {(() => {
              if (errorShow) {
                return (
                  <Modal.Body
                    className="d-flex flex-column justify-content-center py-5"
                    style={{ color: "red", fontSize: "18px" }}
                  >
                    <span
                      style={{
                        display: "inline",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      Oops! Looks like your profile has been blocked. Please
                      write to us at{" "}
                      <a
                        href="mailto:crew@playace.co"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        crew@playace.co
                      </a>
                    </span>
                  </Modal.Body>
                );
              }
              // if (requestError) {
              //   return (
              //     <Modal.Body
              //       className="d-flex flex-column justify-content-center py-5"
              //       style={{ color: "red", fontSize: "18px" }}
              //     >
              //       <span
              //         style={{
              //           display: "inline",
              //           textDecoration: "none",
              //           color: "inherit",
              //         }}
              //       >
              //         Too many otp requests. Please try again after some time
              //       </span>
              //     </Modal.Body>
              //   );
              // }
              switch (isOTPSent) {
                case true:
                  return (
                    <Login
                      phone={phone}
                      setNewUser={setNewUser}
                      handleClose={props.handleClose}
                      handleHost={(otp) => { handleVerify(otp) }}
                      showApiError={apiErrorMessage}
                      otpValue={otpValue}
                    />
                  );
                case false:
                  return (
                    <div>
                      <Signup
                        phone={phone}
                        setNewUser={setNewUser}
                        setOTPSent={setOtpSent}
                        otp={otpFromModel.current}
                        onSignup={onSignup}
                      />
                      {/* <small
                          className="d-block make-pointer text-primary"
                          onClick={() => {
                            setOtpSent(null);
                            setNewUser(!newUser);
                          }}
                        >
                          Already have an account? Login.
                        </small> */}
                    </div>
                  );

                default:
                  return (
                    <Default
                      handleSubmit={handleSubmitRequest}
                      phone={phone}
                      setNewUser={setNewUser}
                      newUser={newUser}
                    />
                  );
              }
            })()}
          </div>
        </div>
      </Modal.Body>
      {/* </Desktop> */}
      {/* <Mobile>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="px-4">
          <div className="d-flex flex-column justify-content-center">
            {(() => {
              if (errorShow) {
                return (
                  <div className="error-popup">
                    <p>Error: {errorShow}</p>
                  </div>
                );
              }
              switch (isOTPSent) {
                case true:
                  return (
                    <Login
                      phone={phone}
                      setNewUser={setNewUser}
                      handleClose={props.handleClose}
                      handleHost={(otp) => { handleVerify(otp) }}
                    />
                  );
                case false:
                  return (
                    <div>
                      <Signup
                        phone={phone}
                        setNewUser={setNewUser}
                        setOTPSent={setOtpSent}
                      />
                      
                    </div>
                  );

                default:
                  return (
                    <Default
                      handleSubmit={handleSubmitRequest}
                      phone={phone}
                      setNewUser={setNewUser}
                      newUser={newUser}
                    />
                  );
              }
            })()}
          </div>
        </Modal.Body>
      </Mobile> */}
    </React.Fragment>
  );
}
