import { useState } from 'react';

const useForm = (initialValues) => {
    const [form, setForm] = useState(initialValues);

    const updateForm = (newForm) => {
        setForm((prevForm) => ({
            // ...prevForm,
            ...newForm,
        }));
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: {
                ...prevForm[name],
                value: value,
                isErrored: false,
                errorMessage: ""
            },
        }));
    };

    const handleSubmit = (e, onSubmit) => {
        e.preventDefault();
        let isValid = true;
        let newForm = { ...form };

        for (let key in form) {
            if (form[key].isRequired && form[key].value === "") {
                isValid = false;
                newForm[key] = {
                    ...form[key],
                    isErrored: true,
                    errorMessage: form[key].requiredMessage ?? "This field is required",
                };
            } else if (form[key].regex && !new RegExp(form[key].regex).test(form[key].value)) {
                isValid = false;
                newForm[key] = {
                    ...form[key],
                    isErrored: true,
                    errorMessage: form[key].patternErrorMessage ?? "This field is not valid",
                };
            } else {
                newForm[key] = {
                    ...form[key],
                    isErrored: false,
                    errorMessage: ""
                };
            }
        }
        setForm(newForm);

        if (isValid) {
            const data = Object.keys(form).reduce((acc, key) => {
                acc[key] = form[key].value;
                return acc;
            }, {});

            onSubmit(data);
        }
    };

    return { form, handleFormChange, handleSubmit, updateForm };
};

export default useForm;
