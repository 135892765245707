import pizzeria1441 from "./1441.png";
import brewHouse from "./brew_white.png";
import crossword from "./crossword rect logo.jpg";
import dotShot from "./dotshot.png";
import starSportsSelect from "./fNNMuvtQ.png";
import ITC from "./itc_white.png";
import harshChocolates from "./LOGO HARSH-Colour-1 (1).jpg";
import limese from "./image copy 2.png";
import zolo from "./image.png";
import chilis from "./Logo_Growth_C.png";
import bacardi from "./bacardi_white.png";
import rageCoffee from "./rage_white.png";
import OML from "./OML_Logo_2016.png";
import OnoTeas from "./Ono Teas Logo Manish.png";
import sipWise from "./sipwise_white.png";
import svami from "./swami_white.png";
import things2Do from "./things2do logo.png";
import WeWork from "./we_work_white.png";
import whiteOwl from "./WO-logo-horizontal-on-white-bright-with-margin.png";
import workAmp from "./wa_white.png";

import logo1 from "./1.png";
import logo2 from "./2.jpg";
import logo3 from "./3.png";
import logo4 from "./4.jpg";
import logo5 from "./5.png";
import logo6 from "./6.png";
import logo7 from "./7.png";
import logo8 from "./8.png";
import logo9 from "./9.jpeg";
import logo10 from "./10.png";
import logo11 from "./11.png";
import logo12 from "./12.png";
import logo13 from "./13.png";
import logo14 from "./14.png";
import logo15 from "./15.png";
import logo16 from "./16.png";

// export const brandLogos = [
//   pizzeria1441,
//   brewHouse,
//   crossword,
//   dotShot,
//   starSportsSelect,
//   ITC,
//   harshChocolates,
//   limese,
//   zolo,
//   chilis,
//   bacardi,
//   rageCoffee,
//   OML,
//   OnoTeas,
//   sipWise,
//   svami,
//   things2Do,
//   WeWork,
//   whiteOwl,
//   workAmp,
// ];

export const brandLogos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
];