import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import arrow from "../../../assets/arrow.svg";

export default function Expander(props) {
  let [showtext, setShowText] = useState(false);
  let [byDefaultOpen, setByDefaultOpen] = useState(false);

  useEffect(() => {
    if (props.byDefault) {
      setByDefaultOpen(true);
    }
  }, []);

  let cursorStyle = {
    cursor: "pointer",
    userSelect: "none",
  };

  return (
    <div className="mt-5 expanderCont border-bottom pb-5">
      <div
        onClick={() => setShowText(!showtext)}
        className="d-flex justify-content-between"
        style={cursorStyle}
      >
        <h3>{props.title}</h3>
        {showtext || byDefaultOpen ? (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-up"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M6 15l6 -6l6 6" />
            </svg>
          </div>
        ) : (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M6 9l6 6l6 -6" />
            </svg>
          </div>
        )}
      </div>
      {showtext || byDefaultOpen ? (
        <div className="mt-4 kumBh">{parse(props.data)}</div>
      ) : null}
    </div>
  );
}
