import React, { useState } from 'react';

const StarRatings = ({ totalStars, onChange }) => {
  const [selectedStars, setSelectedStars] = useState(0);

  const handleStarClick = (selected) => {
    setSelectedStars(selected);
    onChange(selected);
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <Star
          key={index}
          selected={index < selectedStars}
          onClick={() => handleStarClick(index + 1)}
        />
      ))}
    </div>
  );
};

const Star = ({ selected, onClick }) => (
  <span style={{ color: selected ? '#ff1844' : 'gray', fontSize: "1.7em" ,cursor:"pointer" }} onClick={onClick}>
    &#9733;
  </span>
);

export default StarRatings;
