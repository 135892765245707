import React from "react";
import { Container } from "react-bootstrap";
import "./AboutUs.css";
import founder from "../../assets/aboutUs/Rectangle 109.png";
import discoBall from "../../assets/image 7.svg";
import newLogo from "../../assets/newLogo.png";

import team1 from "../../assets/aboutUs/Rectangle 109 (1).png";
import team2 from "../../assets/aboutUs/Rectangle 109 (2).png";
import team3 from "../../assets/aboutUs/Rectangle 109 (3).png";
import team4 from "../../assets/aboutUs/Rectangle 109 (4).png";
import team5 from "../../assets/aboutUs/Rectangle 109 (5).png";
import team6 from "../../assets/aboutUs/Rectangle 109 (6).png";
import team7 from "../../assets/aboutUs/Rectangle 109 (7).png";

import story1 from "../../assets/aboutUs/smart-people.png";
import story2 from "../../assets/aboutUs/smart-people (1).png";
import story3 from "../../assets/aboutUs/Group 1000006154.png";
import story4 from "../../assets/aboutUs/smart-people (3).png";

import partyimageSec1_1 from "../../assets/aboutUs/image 56.png";
import partyimageSec1_2 from "../../assets/aboutUs/image 57.png";
import partyimageSec1_3 from "../../assets/aboutUs/image 58.png";
import partyimageSec1_4 from "../../assets/aboutUs/image 59.png";

import partyimageSec2_1 from "../../assets/aboutUs/image 35.png";
import partyimageSec2_2 from "../../assets/aboutUs/image 60.png";
import partyimageSec2_3 from "../../assets/aboutUs/image 61.png";
import partyimageSec2_4 from "../../assets/aboutUs/image 62.png";
import partyimageSec2_5 from "../../assets/aboutUs/image 63.png";

import partyimageSec3_1 from "../../assets/aboutUs/image 14.png";
import partyimageSec3_2 from "../../assets/aboutUs/image 15.png";
import partyimageSec3_3 from "../../assets/aboutUs/image 16.png";
import partyimageSec3_4 from "../../assets/aboutUs/image 34.png";
import { Default, Mobile } from "../helpers/Breakpoints";

export default function AboutUs() {
  const videos = [
    "https://cdn.pixabay.com/video/2017/06/21/10184-222917589_tiny.mp4",
    "https://cdn.pixabay.com/video/2017/06/21/10180-222898806_tiny.mp4",
    "https://cdn.pixabay.com/video/2015/11/03/1257-144566582_tiny.mp4",
    "https://cdn.pixabay.com/video/2017/06/21/10184-222917589_tiny.mp4",
    "https://cdn.pixabay.com/video/2017/06/21/10180-222898806_tiny.mp4",
    "https://cdn.pixabay.com/video/2015/11/03/1257-144566582_tiny.mp4",
    "https://cdn.pixabay.com/video/2017/06/21/10184-222917589_tiny.mp4",
    "https://cdn.pixabay.com/video/2017/06/21/10180-222898806_tiny.mp4",
    "https://cdn.pixabay.com/video/2015/11/03/1257-144566582_tiny.mp4",
    // Add more video URLs as needed
  ];

  return (
    <>
      <section className="videoHeroCont">
        <div className="video-marquee-container">
          <div className="video-marquee-row">
            <div className="video-marquee">
              <img src={partyimageSec1_1} />
              <img src={partyimageSec1_2} />
              <img src={partyimageSec1_3} />
              <img src={partyimageSec1_4} />
              <img src={partyimageSec1_1} />
              <img src={partyimageSec1_2} />
              <img src={partyimageSec1_3} />
              <img src={partyimageSec1_4} />
            </div>
          </div>
          <div className="video-marquee-row">
            <div className="video-marquee right">
              <img src={partyimageSec2_1} />
              <img src={partyimageSec2_2} />
              <img src={partyimageSec2_3} />
              <img src={partyimageSec2_4} />
              <img src={partyimageSec2_5} />
              <img src={partyimageSec2_1} />
              <img src={partyimageSec2_2} />
              <img src={partyimageSec2_3} />
              <img src={partyimageSec2_4} />
              <img src={partyimageSec2_5} />
            </div>
          </div>
          <div className="video-marquee-row">
            <div className="video-marquee">
              <img src={partyimageSec3_1} />
              <img src={partyimageSec3_2} />
              <img src={partyimageSec3_3} />
              <img src={partyimageSec3_4} />
              <img src={partyimageSec3_1} />
              <img src={partyimageSec3_2} />
              <img src={partyimageSec3_3} />
              <img src={partyimageSec3_4} />
            </div>
          </div>
        </div>

        <div className="bgBlurCont">
          <h1>
            For the Love of House Parties - Meet Your New Offline Social
            Network.
          </h1>
        </div>
      </section>

      <section
        className="wholegbg_black meetLeader_cont"
        style={{ margin: "6rem auto 0 auto" }}
      >
        <Container>
          <div className="aboutus_tit">
            <h3>Meet our team</h3>
          </div>

          <div className="foundersCont">
            <div className="founderImg">
              <img src={founder} alt="" />
            </div>

            <div className="founderContent">
              <h5>Founder</h5>
              <h4>Shrinivas Shinde</h4>

              <div className="akas">
                <p>a.k.a. The Silent Operator</p>
              </div>

            </div>
          </div>

          <div className="teamMembersCont">
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team1} alt="" />
              </div>
              <h3>Shantanu Datar</h3>
              <div className="akas">
                <p>a.k.a. The Pied Piper</p>
              </div>
              <h4>Nomadic Catalyst - Region Alchemist</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team2} alt="" />
              </div>
              <h3>Dhiraj SHetty</h3>
              <div className="akas">
                <p>a.k.a. The Go-To Guy</p>
              </div>
              <h4>Operations and People</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team3} alt="" />
              </div>
              <h3>Viraanch Joshi</h3>
              <div className="akas">
                <p>a.k.a. The unusual GenZ</p>
              </div>
              <h4>Business Development - Finance and Brands</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team4} alt="" />
              </div>
              <h3>Vaibhavi Nagda</h3>
              <div className="akas">
                <p>a.k.a. Miss Know it all</p>
              </div>
              <h4>Social Media and Tease Favorite</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team5} alt="" />
              </div>
              <h3>Sourabh kumar</h3>
              <div className="akas">
                <p>a.k.a. The Nice Guy</p>
              </div>
              <h4>Region Head - Pune</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team6} alt="" />
              </div>
              <h3>Sanskriti Das</h3>
              <div className="akas">
                <p>a.k.a. Chhotu sa Bacchhu</p>
              </div>
              <h4>Region Head - Bangalore</h4>
            </div>
            <div className="teamMemCard">
              <div className="teamMemImgBx">
                <img src={team7} alt="" />
              </div>
              <h3>Debosmita Mukherjee</h3>
              <div className="akas">
                <p>a.k.a. The deccan power house</p>
              </div>
              <h4>Region Head - Hyderabad</h4>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="wholegbg_black ourStoryCont"
        style={{ margin: "6rem auto 0 auto" }}
      >
        <Container>
          <div className="aboutus_tit">
            <h3>our Journey</h3>
          </div>

          <div className="timeline">
            <div className="timeline_cont top">
              <div className="timeline_img_cont left">
                <img src={story1} alt="" />
              </div>
              <div className="timeline_content alignSelf">
                <h5>The Spark</h5>
                <p>
                  Our founder has always cherished the relaxed vibe of house
                  parties, favoring them over the hassles of nightclubs or
                  expensive venues. Finding joy in connecting over laughter in a
                  casual setting, he noticed a gap—house parties weren't as
                  frequent due to cultural norms of living with family. This
                  insight sparked his vision to make house parties more
                  accessible and enjoyable for everyone.
                </p>
              </div>
            </div>
            <div className="timeline_cont">
              <Default>
                <div className="timeline_content left text-end">
                  <h5>The Eureka Moment</h5>
                  <p>
                    Inspiration struck in 2015 when our founder fell in love
                    with Airbnb, drawn to its innovative concept and
                    user-friendly design. During a trip to LA in 2016, a chance
                    encounter with a lively group heading to a house party
                    sparked a eureka moment—create an 'Airbnb for House
                    Parties.' This nostalgic experience solidified his resolve
                    to revolutionize social gatherings.
                  </p>
                </div>
                <div className="timeline_img_cont">
                  <img src={story2} alt="" />
                </div>
              </Default>
              <Mobile>
                <div className="timeline_img_cont left">
                  <img src={story2} alt="" />
                </div>

                <div className="timeline_content">
                  <h5>The Eureka Moment</h5>
                  <p>
                    Inspiration struck in 2015 when our founder fell in love
                    with Airbnb, drawn to its innovative concept and
                    user-friendly design. During a trip to LA in 2016, a chance
                    encounter with a lively group heading to a house party
                    sparked a eureka moment—create an 'Airbnb for House
                    Parties.' This nostalgic experience solidified his resolve
                    to revolutionize social gatherings.
                  </p>
                </div>
              </Mobile>
            </div>
            <div className="timeline_cont">
              <div className="timeline_img_cont left">
                <img src={story3} alt="" />
              </div>
              <div className="timeline_content alignSelf">
                <h5>Turning Vision into Reality</h5>
                <p>
                  Seizing the opportunity, our founder returned to India and
                  embarked on realizing his vision. Despite initial challenges,
                  his determination prevailed. On November 11, 2017, Playace.co
                  was launched, connecting partygoers with enthusiastic hosts.
                  Our first party in Mumbai marked the dawn of a new era in
                  socializing, driven by a passion for fostering meaningful
                  connections
                </p>
              </div>
            </div>
          </div>

          <div className="whyPlayAce">
            <div className="bigTit">
              <h3>Why</h3>
              <img src={newLogo} alt="" />
            </div>

            <p>
              Many pronounce it differently, adding to the intrigue. It’s
              pronounced as ‘PLACE’ because, when planning, the first question
              often is, ‘Which place are we going?’ It’s all about finding the
              perfect spot for a great time.
            </p>
          </div>

          <div className="lastStory">
            <div className="timeline_cont top">
              <Default>
                <div className="timeline_content alignSelf left text-end">
                  <h5>Our Journey So Far</h5>
                  <p>
                    Over the past few years, we have successfully hosted
                    hundreds of house parties across various cities in the
                    country without any mishaps. Along the way, we've had the
                    opportunity to collaborate with numerous domestic and
                    international brands. Our commitment to safety and fun has
                    built a strong, vibrant community of party-goers and hosts
                    alike. We continue to innovate and expand, creating
                    unforgettable experiences and fostering connections that go
                    beyond the party. With each event, we aim to elevate the
                    house party culture, making it more accessible, exciting,
                    and inclusive for everyone.
                  </p>
                </div>
                <div className="timeline_img_cont">
                  <img src={story4} alt="" />
                </div>
              </Default>
              <Mobile>
                <div className="timeline_img_cont left">
                  <img src={story4} alt="" />
                </div>
                <div className="timeline_content alignSelf">
                  <h5>Our Journey So Far</h5>
                  <p>
                    Over the past few years, we have successfully hosted
                    hundreds of house parties across various cities in the
                    country without any mishaps. Along the way, we've had the
                    opportunity to collaborate with numerous domestic and
                    international brands. Our commitment to safety and fun has
                    built a strong, vibrant community of party-goers and hosts
                    alike. We continue to innovate and expand, creating
                    unforgettable experiences and fostering connections that go
                    beyond the party. With each event, we aim to elevate the
                    house party culture, making it more accessible, exciting,
                    and inclusive for everyone.
                  </p>
                </div>
              </Mobile>
            </div>
          </div>

          <div className="changedLives">
            <div className="discoBallCont">
              <img src={discoBall} alt="" />
            </div>

            <div className="aboutus_tit">
              <h3>Spreading Joy with Playace</h3>
            </div>

            <p>
              Our journey goes beyond parties—it's about creating fun and
              memorable experiences. Every day, we strive to foster connections
              and create cozy environments where like-minded individuals share
              passions and have a blast. Embracing 'house parties' for their
              warmth and community spirit, we empower hosts to discover their
              spaces' true potential. Resisting industry pressures to rebrand,
              we believe in the excitement of 'house parties,' where hosts open
              doors to new social scenes, secondary incomes, and life-changing
              connections. Whether seeking a break from routine or kindred
              spirits, booking a Playace house party offers transformative fun
              and social experiences.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
}
