import axios from 'axios';
import { callSdkCheckAPI, NEW_URL, sdkCheckAPI } from '../../../api/requests';
import { init } from 'onfido-sdk-ui';


export async function startOnFidoVerification(userObj, hanldeVerificationStatus) {
  try {
    const fullNameParts = userObj?.fullName?.split(' ');
    const firstName = fullNameParts?.[0];
    const lastName = fullNameParts?.slice(1)?.join(' ');

    const response = await axios.post(`${NEW_URL}api/users/generate-sdkToken`, {
      userId: userObj.userId,
      first_name: firstName,
      last_name: lastName,
    });

    const sdkToken = response.data.sdkToken;
    const applicantId = response.data.applicantId;

    let onfido = init({
      token: sdkToken,
      containerId: 'onfido-mount',
      useModal: true,
      isModalOpen: true,
      shouldCloseOnOverlayClick: false,
      onModalRequestClose: () => {
        hanldeVerificationStatus('In Progress');
        onfido.setOptions({ isModalOpen: false });
      },
      onComplete: async () => {

        // const onfidoCheckId = await axios.post(`${NEW_URL}api/users/sdk-check`, {
        //   applicantId: applicantId
        // }, {
        //   headers: {
        //     Authorization: `Bearer ${userObj.token}`,
        //   }
        // });

        const onfidoCheckId = await sdkCheckAPI({ applicantId: applicantId });

        if (onfidoCheckId && onfidoCheckId.checkId !== '') {
          hanldeVerificationStatus('In Progress');
        } else {
          hanldeVerificationStatus('Error');
        }

        onfido.setOptions({ isModalOpen: false });
      },
      steps: [
        {
          type: 'welcome',
          options: {
            title: 'Verify your details',
            descriptions: [
              'To start hosting and attending parties with Playace, you need to verify your identity. It only takes a few minutes.',
            ],
          },
        },
        {
          type: 'document',
          options: {
            documentTypes: {
              passport: false,
              driving_licence: false,
              national_identity_card: true,
            },
          },
        },
        'face',
        'complete',
      ],
    });

    return sdkToken;
  } catch (error) {
    console.error('Error starting Onfido verification:', error);
    throw error;
  }
}