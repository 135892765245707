import React, { useRef, useState } from "react";
import HostPartyBanner from "../Home/HostPartyBanner";
import { useParams, useLocation, Link } from "react-router-dom";
import { cancelPaymentRefundByEvent } from "../../../api/requests";
import { Container, Modal } from "react-bootstrap";
import { RenderUserObj } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGuestCount,
  updatePromoCode,
} from "../../../actions/guestBookActions";
import {
  formatTime,
  showErrorToast,
  showSuccessToast,
} from "../../../utils/utils";

export default function HostParties({ parties, refreshPage, isShowHostRequestModel = false, setShowHostRequestModel }) {
  let userObj = RenderUserObj(useSelector((state) => state.auth.data));

  const [ticketModal, setTicketModal] = useState(false);
  const [response, setResponse] = useState("");
  const [reason, setReason] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const clickedEvent = useRef(null);

  const handleCancel = () => {
    let obj = {
      eventId: clickedEvent.current.eventId,
      reason: reason,
    };
    setIsSubmitted(true);
    cancelPaymentRefundByEvent(userObj.token, obj)
      .then((response) => {
        if (response.status == "success") {
          setResponse("Event Cancelled Successfully.");
          showSuccessToast("Event Cancelled Successfully.");
          handleTicketModal();

          refreshPage();
          setTimeout(() => {
            setResponse(null);
            setTicketModal(false);
            setIsSubmitted(false);
          }, 2000);
        } else {
          setResponse("Something went wrong, try again in some time.");
          showErrorToast("Something went wrong, try again in some time.");
          handleTicketModal();
          setIsSubmitted(false);
        }
      })
      .catch((error) => {
        setResponse("Something went wrong, try again in some time");
        setIsSubmitted(false);
      });
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const handleTicketModal = () => {
    setTicketModal(false);
  };

  const handleClearData = () => {
    dispatch(updateGuestCount(1));
    dispatch(updatePromoCode(""));
  };

  const showCancelModal = () => {
    return (
      <Modal
        backdrop="static"
        show={ticketModal}
        onHide={handleTicketModal}
        centered
      >
        <Container className=" text-center cancelCommon">
          <h6 className="text-uppercase">
            Oh No! Don't cancel the house party Please...
          </h6>
          <p>
            Please write down your concerns and we'll try our best to resolve
            them.
          </p>
          <br></br>
          <textarea
            name="reasonForCancelation"
            type="text"
            onChange={handleReason}
            placeholder="Reason For Cancellation"
            value={reason}
            rows={4}
          ></textarea>
          {/* {response && <p>{response}</p>} */}
          <div className="commonPopUpBtn">
            <button
              onClick={handleCancel}
              className="btn btn-reg btn-padding text-primary"
              disabled={isSubmitted}
            >
              Confirm Cancellation
            </button>
            <button
              onClick={handleTicketModal}
              className="btn btn-reg btn-primary btn-padding"
            >
              Go Back
            </button>
          </div>
        </Container>
      </Modal>
    );
  };

  return (
    <section>
      <div className="spacingtopB">
        <HostPartyBanner isShowHostRequestModel={isShowHostRequestModel} setShowHostRequestModel={setShowHostRequestModel} />
      </div>

      {showCancelModal()}

      <div className="container">
        <div className="partiesTimelineCont">
          <div className="partytimeLine_head">
            <h2 className="upperTit">Your Hosted Parties</h2>

            <div className="timeLineDottedCont">
              <div className="partyDateCont">
                <div className="partydateHead">
                  <h3>Upcoming Parties</h3>
                </div>
                {parties?.upcomingEvents?.map((party) => {
                  return (
                    <div className="wholeCardDetails">
                      <div className="covertimeline">
                        <Link
                          className="link-unstyled"
                          to={{
                            pathname: `/parties/${party.eventId}`,
                            // location: location.pathname,
                          }}
                          onClick={() => {
                            handleClearData();
                          }}
                        >
                          <div className="partyBgImg">
                            <img src={party?.images?.[0]} alt="" />
                          </div>
                        </Link>

                        <div className="partyTimelineDetailsCont">
                          <div>
                            <h2 className="titleParty">{party?.title}</h2>
                            {/* <p className="hostedBy">
                              Hosted By {party?.hostName}
                            </p> */}
                          </div>

                          <div className="locationNdate flexCenter">
                            <div className="dateCont flexCenter commonpartyInfo">
                              <div className="iconSvg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                  <path d="M16 3l0 4"></path>
                                  <path d="M8 3l0 4"></path>
                                  <path d="M4 11l16 0"></path>
                                  <path d="M8 15h2v2h-2z"></path>
                                </svg>
                              </div>
                              <p>
                                {party?.eventDate} |{" "}
                                {formatTime(party?.startTime)}
                              </p>
                            </div>
                            <div className="locationCont flexCenter commonpartyInfo">
                              <div className="iconSvg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                                </svg>
                              </div>
                              <p>
                                {party?.city}, {party?.location}
                              </p>
                            </div>
                          </div>

                          <div className="twoBtnsCont">
                            <button
                              className={
                                party?.status === "Pending"
                                  ? "approvePending"
                                  : party?.status === "Rejected"
                                    ? "approveRejected"
                                    : ""
                              }
                            >
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-check"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M5 12l5 5l10 -10" />
                              </svg> */}
                              {party?.status === "Rejected" ? "Disapproved" : party?.status}
                            </button>

                            {party?.status === "live" && (
                              <button
                                className="cancel"
                                onClick={() => {
                                  // console.log("ASdfdasf");
                                  setTicketModal(true);
                                  clickedEvent.current = party;
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-cancel"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                  <path d="M18.364 5.636l-12.728 12.728" />
                                </svg>
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="partyDateCont">
                <div className="partydateHead">
                  <h3>Past Parties</h3>
                </div>
                {parties?.pastEvents?.map((party) => {
                  return (
                    <Link
                      className="link-unstyled"
                      to={{
                        pathname: `/parties/${party.eventId}`,
                        // location: location.pathname,
                      }}
                      onClick={() => {
                        handleClearData();
                      }}
                    >
                      <div className="wholeCardDetails">
                        <div className="covertimeline">
                          <div className="partyBgImg">
                            <img src={party?.images?.[0]} alt="" />
                          </div>

                          <div className="partyTimelineDetailsCont">
                            <div>
                              <h2 className="titleParty">{party?.title}</h2>
                              {/* <p className="hostedBy">
                                Hosted By {party?.hostName}
                              </p> */}
                            </div>

                            <div className="locationNdate flexCenter">
                              <div className="dateCont flexCenter commonpartyInfo">
                                <div className="iconSvg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                    <path d="M16 3l0 4"></path>
                                    <path d="M8 3l0 4"></path>
                                    <path d="M4 11l16 0"></path>
                                    <path d="M8 15h2v2h-2z"></path>
                                  </svg>
                                </div>
                                <p>
                                  {party?.eventDate} |{" "}
                                  {formatTime(party?.startTime)}
                                </p>
                              </div>
                              <div className="locationCont flexCenter commonpartyInfo">
                                <div className="iconSvg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                                  </svg>
                                </div>
                                <p>
                                  {party?.city}, {party?.location}
                                </p>
                              </div>
                            </div>

                            <div className="twoBtnsCont">
                              <button
                                className={
                                  party?.status === "Pending"
                                    ? "approvePending"
                                    : party?.status === "Rejected"
                                      ? "approveRejected"
                                      : ""
                                }
                              >
                                {party?.status === "Rejected" ? "Disapproved" : party?.status}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>

              {/* <div className="partyDateCont">
                <div className="partydateHead">
                  <h3>Tomorrow, 13th November</h3>
                </div>

                <div className="wholeCardDetails">
                  <div className="covertimeline">
                    <div className="partyBgImg">
                      <img
                        src="https://images.unsplash.com/photo-1562066197-ee227ba3f502?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>

                    <div className="partyTimelineDetailsCont">
                      <div>
                        <h2 className="titleParty">
                          Luxe Lounge: A Penthouse Social Affair
                        </h2>
                        <p className="hostedBy">Hosted By Nomi Nomi</p>
                      </div>

                      <div className="locationNdate flexCenter">
                        <div className="dateCont flexCenter commonpartyInfo">
                          <div className="iconSvg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                              <path d="M16 3l0 4"></path>
                              <path d="M8 3l0 4"></path>
                              <path d="M4 11l16 0"></path>
                              <path d="M8 15h2v2h-2z"></path>
                            </svg>
                          </div>
                          <p>Jul 13 | Sat</p>
                        </div>
                        <div className="locationCont flexCenter commonpartyInfo">
                          <div className="iconSvg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                              <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                            </svg>
                          </div>
                          <p>mumbai, test city</p>
                        </div>
                      </div>

                      <div className="ticketsCont flexCenter commonpartyInfo">
                        <div className="iconSvg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-ticket"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 5l0 2" />
                            <path d="M15 11l0 2" />
                            <path d="M15 17l0 2" />
                            <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" />
                          </svg>
                        </div>
                        <p>2 tickets</p>
                      </div>

                      <div className="twoBtnsCont">
                        <button className="approvePending">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-mark"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 19v.01" />
                            <path d="M12 15v-10" />
                          </svg>
                          Approved Pending
                        </button>
                        <button className="cancel">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-cancel"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                            <path d="M18.364 5.636l-12.728 12.728" />
                          </svg>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
