import React from 'react';

export default function Input(props) {

    let [value, setValue] = React.useState("");

    function handleChange(event) {
        setValue(event.target.value);
    }

    return (
        <div className={`group ${props.classes}`}>
            <input className="mb-0" type="text" required value={props.value === "" ? value : props.value} onChange={handleChange} />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{props.placeholder}</label>
        </div>
    );
}