export default function calculateDiscount(
	coupon_limit,
	coupon_discount_percent,
	event_amount,
	guestCount
) {
	let discount = (event_amount * guestCount * coupon_discount_percent) / 100;
	if (coupon_limit === null)
		discount = (event_amount * guestCount * coupon_discount_percent) / 100;
	else {
		if (discount > coupon_limit) {
			discount = coupon_limit;
		}
	}
	return discount;
}
