import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  RenderDate,
  RenderUserObj,
  Loader,
  isGuestListEmpty,
} from "../../../helpers";
import parse from "html-react-parser";
import DefaultDP from "../../../../assets/DP.png";

export default function About({ pageType = null, title, content, ...props }) {
  let [show, setShow] = useState(false);
  const history = useHistory();

  const formatTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  if (pageType === "event-reviews") {
    // Conditionally render the card if content.title exists
    if (!content.title) {
      return null; // If content.title doesn't exist, return null to render nothing
    }
  }

  return (
    <>
      {pageType != "event-reviews" ? (
        <div className="mt-5 aboutParty">
          <h3>{title}</h3>
          <p style={{ display: "block" }}>{parse(`${content}`)}</p>
        </div>
      ) : (
        <Container className="mt-5 mb-5 party-container">
          <Row className="justify-content-md-center">
            <Col xs={12} md={8}>
              <div className="shadow rounded-lg">
                <Row className="px-4 pt-4 pb-3">
                  <Col>
                    <p className="mb-0">
                      <span className="badge badge-primary p-2 text-uppercase rounded-sm">
                        house party
                      </span>
                    </p>
                    <h5 className="fw-bold mt-2 mb-2">{content.title}</h5>
                    {content && content.startTime && content.location ? (
                      <>
                        <small>
                          {content.location}, {content.city}
                        </small>{" "}
                        <br />
                        <small>{RenderDate(content.eventDate)}</small> <br />
                        <small>
                          {formatTime(content.startTime)} -{" "}
                          {formatTime(content.endTime)}
                        </small>
                      </>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="float-right text-center">
                      {content && content.host ? (
                        <>
                          <p>Hosted by</p>
                          <img
                            error={DefaultDP}
                            src={
                              content.hostProfileImage
                                ? content.hostProfileImage
                                : DefaultDP
                            }
                            alt="profile-user"
                            className="rounded-circle cursor-pointer"
                            height="40"
                            width="40"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              history.push(
                                "/hostDetails/" +
                                  content.host +
                                  "/" +
                                  content.eventId
                              );
                            }}
                          />

                          <br />
                          <small>{content.hostName}</small>
                          <br />
                          <br />
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
