import axios from "axios";
import { URL, NEW_URL, createOrder } from "../../../api/requests";
import { axiosInstance } from "../../../api/config";
import { showErrorToast } from "../../../utils/utils";

//APPENDS THE RAZROPAY SCRIPT TO THE PAGE HTML BODY

export function loadRazorpay() {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  document.body.appendChild(script);
}

//SHOWS THE RAZORPAY MODAL
export async function showRazorpay(
  orderData = {},
  userObj,
  amount,
  event,
  guests,
  couponData = {},
  callback
) {
  //TODO: Edit options.key for dev and production
  console.log(orderData, event, event.data.title, userObj.token);
  let paymentDiv = document.querySelector(".loader-booking-container");
  let showErrorMessagePay = document.querySelector(".show-error-message-pay");

  let paymentButton = document.querySelector(".payment");
  // paymentButton.disabled = true;
  // console.log("process.env.REACT_APP_RZP_KEY", process.env.REACT_APP_RZP_KEY);
  // console.log(`${process.env.REACT_APP_BASE_URL}?paid=true`);
  let messageBody = `Event Booking!
*User info:* ${userObj?.email} | ${userObj?.phone}
*Event info:* ${event.data.title}
*Payment info:* "Created"  | "Card" | ${amount}`;

  // let messageBody =
  //          `
  // 		  Event Booking!
  //           *User info:* ${userObj?.email} | ${userObj?.phone}
  //           *Event info:* ${event.data.title}
  //           *Payment info:* "Created"  | "Card" | ${amount}
  // 		  `;


  createOrder({
    eventId: event.data.eventId,
    couponData: couponData,
    guests: guests,
    message: messageBody,
  }).then((orderData) => {
    console.log("orderData", orderData);
    if (orderData.status === "success") {
      const options = {
        key: process.env.REACT_APP_RZP_KEY,
        amount: orderData?.data?.amount,
        currency: "INR",
        name: "Playace.co",
        description: `${event.data.title} | ${event.data.eventId}`,
        // image: `${process.env.REACT_APP_BASE_URL}/static/media/logo.b76536f4.png`,
        order_id: orderData?.data?.id,
        handler: async function (response) {
          // paymentButton.value = "Hold On...";

          console.log("RAZOR response", response);
          // let paymentDetails = {
          // 	id : response.razorpay_payment_id,
          // 	amount : amount * 100,
          // 	currency : "INR"
          // }
          // try {
          // 	let capture = await capturePayment(paymentDetails,userObj);
          // 	if(capture.status === 'success'){
          // 		let addusers = await addGuestToUsers(guests, userObj);
          // 		if(addusers.status === 'success'){
          // 			paymentButton.disabled = false;
          // 			let store = await addParticipants(capture, userObj, guests, event, couponData);
          // 			if(store.status === 'success'){
          const queryParams = new URLSearchParams({
            paid: true,
            title: event?.data?.title,
            locationOg: event?.data?.location,
            city: event?.data?.city,
            eventDate: event?.data?.eventDate,
            partyImage: event?.data?.images?.[0],
          }).toString();
          window.location.href = `/?${queryParams}`;
          // 			}
          // 		}
          // 	}
          // } catch (error) {
          // 	console.log("error", error);
          // }
        },
        prefill: {
          name: userObj?.fullName,
          email: userObj?.email,
          contact: JSON.stringify(userObj?.phone),
        },
        theme: {
          color: "#FF1844",
        },
        modal: {
          ondismiss: function () {
            document.body.style.overflow = '';
            if (paymentDiv !== null) paymentDiv.style.display = "none";
            if (paymentButton != null) paymentButton.disabled = false;
          },
        },
      };
      let paymentObj = new window.Razorpay(options);
      paymentObj.open();
    } else {
      showErrorToast(orderData?.message ?? "Something went wrong");
      if (showErrorMessagePay) {
        showErrorMessagePay.innerHTML = orderData.error
          ? orderData.error
          : "Oops! Something went wrong. Try again later.";
        showErrorMessagePay.style.display = "block";
        setTimeout(() => {
          showErrorMessagePay.style.display = "none";
        }, 5000);
      }
    }
  }).catch(() => {

  }).finally(() => {
    callback()
  });
}

//SENDS THE REQUEST TO RAZORPAY AND CREATES AN ORDER ON THE BACKEND
export function sendRazorpayRequest(userObj, amount, event, guests) {
  console.log(userObj, amount, event, guests);

  if (document.querySelector(".loader-booking-container") !== null) {
    let paymentDiv = document.querySelector(".loader-booking-container");
    paymentDiv.style.display = "block";
  }

  const token = userObj.token;
  const auth = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  axios
    .post(
      `${URL}v1/order/create`,
      {
        amount: amount * 100,
      },
      auth
    )
    .then((response) => {
      showRazorpay(response.data, userObj, amount, event, guests);
      return;
    })
    .catch((error) => {
      console.log(error);
    });

  return true;
}

async function addParticipants(
  paymentDetails,
  userObj,
  guests,
  event,
  couponData
) {
  const totalGuests = guests.length;
  let paymentData = paymentDetails.data;
  paymentData["amount"] = parseInt(paymentData["amount"]) / totalGuests;

  let insertParticipant = {};

  for (let i = 0; i < totalGuests; i++) {
    const guest = guests[i];

    insertParticipant = await fetch(NEW_URL + "api/participant", {
      method: "POST",
      body: JSON.stringify({
        eventId: event.data.eventId,
        participant: {
          fullName: guest.fullName,
          phone: guest.phone,
          gender: guest.gender,
          profileImage: guest.profileImage || null,
          status: "Pending",
        },
        transaction: paymentData,
        coupons: couponData,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userObj.token,
      },
    });
  }
  return await insertParticipant.json();
}

async function addGuestToUsers(guests, userObj) {
  const insertGuests = await fetch(NEW_URL + "api/users/insertGuests", {
    method: "POST",
    body: JSON.stringify(guests),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userObj.token,
    },
  });
  return await insertGuests.json();
}

async function capturePayment(paymentData, userObj) {
  const capturePaymentData = await fetch(
    NEW_URL + "api/participant/paymentCapture",
    {
      method: "POST",
      body: JSON.stringify({
        paymentId: paymentData.id,
        amount: paymentData.amount,
        currency: paymentData.currency,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userObj.token,
      },
    }
  );
  return await capturePaymentData.json();
}

export function sendRequestToAttend(
  event,
  payment_id,
  payment_gateway_id,
  guests,
  auth
) {
  let paymentDiv = document.querySelector(".loader-booking-container");
  let paymentButton = document.querySelector(".payment");
  const body = {
    event_id: event.data.id,
    payment_gateway_id: payment_gateway_id,
    payment_id: payment_id,
    playace_code: null,
    user_guests: guests,
  };
  // console.log(body, event, payment_id, payment_gateway_id, guests, auth);

  axios
    .post(`${URL}v1/event/attend/request`, body, auth)
    .then((response) => {
      if (paymentDiv !== null) paymentDiv.style.display = "none";
      if (paymentButton != null) paymentButton.disabled = false;
      console.log("attend email sent. Response: " + response);
      const queryParams = new URLSearchParams({ paid: true }).toString();
      window.location.href = `/?${queryParams}`;
    })
    .catch((error) => {
      console.log(error);
    });
}
