import { isEmpty } from 'lodash';

export default function RenderUserObj(userObj) {
	if (isEmpty(userObj) || userObj === undefined) {
		// console.log(`%c [Auth status]: No user logged in.`, 'background: #4c4a4a; color: #ff3d60');
		return undefined;
	}
	const parsedUserObj = JSON.parse(userObj);
	return parsedUserObj;
}
