import React from "react";
import Img from "react-cool-img";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from "@fortawesome/free-regular-svg-icons";

import BAS from "../assets/img/book a spcae.png";

export default function RenderImage({
  loading,
  setLoading,
  imagesrc,
  classes,
  isSection,
  eventType,
}) {
  return (
    <React.Fragment>
      {classes === "parties-heart" ? (
        <>
          <div
            className={`img-wrapper common justify-content-center animate relative`}
            style={{ position: "relative" }}
          >
            <Img
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                objectFit: "cover",
                width: "100%",
                objectPosition: "top",
                height: "100%",
              }}
              src={imagesrc}
              srcSet={
                isSection === "attend"
                  ? `${imagesrc}?ar=16:9&fit=crop&dpr=1 1x, ${imagesrc}?ar=16:9&fit=crop&dpr=2 2x, ${imagesrc}?ar=16:9&fit=crop&dpr=3 3x`
                  : `${imagesrc}?ar=16:9&dpr=1 1x, ${imagesrc}?ar=16:9&fit=max&dpr=2 2x, ${imagesrc}?ar=16:9&fit=max&dpr=3 3x`
              }
              alt="parties spaces events playace"
              className="img-fluid"
            />

            <div className="badgeCont">
              <div className="badgeIcon">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-home-star"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.258 10.258l-7.258 -7.258l-9 9h2v7a2 2 0 0 0 2 2h4" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h1.5" />
                  <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                </svg> */}

                <h4>{eventType}</h4>
              </div>
            </div>
            {/* <FontAwesomeIcon
              icon={faHeart}
              size="2x"
              className={`text-white ${classes}`}
            ></FontAwesomeIcon> */}
          </div>
        </>
      ) : (
        <>
          <div
            className="img-wrapper common d-block justify-content-center animate relative"
            style={{ position: "relative", minHeight: "200px" }}
          >
            <Img
              style={{
                borderRadius: "8px",
                objectFit: "cover",
                width: "100%",
                minHeight: "100%",
                maxHeight: "100%",
              }}
              // srcSet={`${imagesrc}?dpr=1 1x, ${imagesrc}?fit=max&dpr=2 2x, ${imagesrc}?fit=max&dpr=3 3x`}
              srcSet={`${imagesrc}?ar=16:9&fit=crop&dpr=1 1x, ${imagesrc}?ar=16:9&fit=crop&dpr=2 2x, ${imagesrc}?ar=16:9&fit=crop&dpr=3 3x`}
              src={imagesrc}
              alt="parties spaces events playace"
              className="img-fluid"
              error={BAS}
            />
            <div className="badgeCont">
              <div className="badgeIcon">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-home-star"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.258 10.258l-7.258 -7.258l-9 9h2v7a2 2 0 0 0 2 2h4" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h1.5" />
                  <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                </svg> */}

                <h4>{eventType}</h4>
              </div>
            </div>
            {/* <FontAwesomeIcon
              icon={faHeart}
              size="2x"
              className={`text-white ${classes}`}
            ></FontAwesomeIcon> */}
            {/* <Form.Check type="checkbox"></Form.Check> */}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
